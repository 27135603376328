import { useEffect, useRef } from 'react';
import { type Root, createRoot } from 'react-dom/client';
import type { ThemeEdit } from '../../../types';
import Table from './components/Table';
import Input from './components/Input';
import { RUNTIME_ASSET_URI } from '../../../constants/platform';

export class PreviewElement extends HTMLElement {
    _cssProperties: string | null = null;
    _customCSSCode: string | null = null;
    _stylesheetURLs: string[] = [];

    reactRoot: Root | null = null;
    runtimeStylesheetURL = `${RUNTIME_ASSET_URI.slice(-1) === '/' ? RUNTIME_ASSET_URI.slice(0, -1) : RUNTIME_ASSET_URI}/assets/flow.css`;

    static get observedAttributes() {
        return ['logourl'];
    }

    attributeChangedCallback() {
        this.render();
    }

    connectedCallback() {
        const shadowRoot = this.attachShadow({ mode: 'open' });
        const mountPoint = document.createElement('div');

        shadowRoot.appendChild(mountPoint);
        this.reactRoot = createRoot(mountPoint);
        this.render();
    }

    get stylesheetURLs(): string[] {
        return this._stylesheetURLs;
    }

    set stylesheetURLs(urls: string[]) {
        this._stylesheetURLs = [...urls];
        this.render();
    }

    get cssProperties(): string | null {
        return this._cssProperties;
    }

    set cssProperties(properties: string) {
        this._cssProperties = properties;
        this.render();
    }

    get cssCustomCode(): string | null {
        return this._customCSSCode;
    }

    set cssCustomCode(cssContent: string) {
        this._customCSSCode = cssContent;
        this.render();
    }

    render() {
        const logoURL = this.getAttribute('logourl');
        const hasLogo = typeof logoURL === 'string' && logoURL.length > 0;

        if (this.reactRoot !== null) {
            this.reactRoot.render(
                <div className="flow nav-horizontal">
                    <link rel="stylesheet" href={this.runtimeStylesheetURL} />
                    {typeof this._cssProperties === 'string' && (
                        <style>
                            {`.flow {
                                    ${this._cssProperties}
                                }`}
                        </style>
                    )}
                    {this.stylesheetURLs.map((url) => (
                        <link key={url} rel="stylesheet" href={url} />
                    ))}
                    {typeof this._customCSSCode === 'string' && (
                        <style>{this._customCSSCode}</style>
                    )}
                    {hasLogo && (
                        <header className="page-header">
                            <img src={logoURL} alt="" className="header-image" />
                        </header>
                    )}
                    <section className="content">
                        <div className="container container-vertical">
                            <Input />
                            <Table />
                        </div>
                        <div className="outcomes ">
                            <button className="button button-outcome" type="button">
                                Secondary
                                <span className="loader" />
                            </button>
                            <button className="button button-outcome save" type="button">
                                Primary
                                <span className="loader" />
                            </button>
                        </div>
                    </section>
                </div>,
            );
        }
    }
}

const Preview = ({ theme }: { theme: ThemeEdit }) => {
    useEffect(() => {
        if (!customElements.get('runtime-preview')) {
            customElements.define('runtime-preview', PreviewElement);
        }
    }, []);

    useEffect(() => {
        if (theme.cssURLs !== null && elementRef.current !== null) {
            elementRef.current.stylesheetURLs = theme.cssURLs;
        }
    }, [theme.cssURLs]);

    useEffect(() => {
        if (typeof theme.cssContent === 'string' && elementRef.current !== null) {
            elementRef.current.cssCustomCode = theme.cssContent;
        }
    }, [theme.cssContent]);

    useEffect(() => {
        if (theme.properties !== null && elementRef.current !== null) {
            const propertiesStr = Object.entries(theme.properties ?? {}).reduce(
                (str, [key, value]) => {
                    return `${str}${key}:${value as string};`;
                },
                '',
            );

            elementRef.current.cssProperties = propertiesStr;
        }
    }, [theme.properties]);

    const elementRef = useRef<PreviewElement | null>(null);

    return (
        <runtime-preview
            ref={elementRef}
            logourl={theme.logoURL ?? ''}
            data-testid="runtime-preview"
        />
    );
};

export default Preview;
