import translations from '../../../../../../ts/translations';
import Toggle from '../../../../../../ts/components/inputs/Toggle';

import { useDataAction } from '../../contextProviders/DataActionProvider';
import { CRUD_OPERATION_TYPES } from '../constants';

const SmartSaveToggle = () => {
    const { dataActionToEdit, onUpdateDataActionSmartSave } = useDataAction();
    const { dataAction } = dataActionToEdit;

    const shouldDisplay = dataAction.crudOperationType === CRUD_OPERATION_TYPES.save;

    if (shouldDisplay) {
        return (
            <div className="form-group">
                <label htmlFor="is-smartsave-toggle">
                    <Toggle
                        id="is-smartsave-toggle"
                        isOn={dataAction.isSmartSave}
                        onChange={({ isOn }) => onUpdateDataActionSmartSave({ isOn })}
                        testId="is-smartsave"
                    />
                    {translations.DATA_ACTION_smart_save_toggle_label}
                </label>
            </div>
        );
    }

    return null;
};

export default SmartSaveToggle;
