import ButtonIcon from '../../../../../../buttons/ButtonIcon';
import classNames from 'classnames';

import { usePageEditor } from '../../../../PageEditorProvider';
import { useComposer } from '../../ComposerProvider';
import { isNullOrEmpty } from '../../../../../../../utils/guard';
import { Warning, DotsSix, Trash } from '@phosphor-icons/react';
import { isComponentConfigured } from '../../../../../utils';
import type { LegacyRef, ReactElement } from 'react';
import type { ComposerElement, PreviewMode } from '../../../../../../../types';

interface Props {
    id: string;
    parentId: string | null | undefined;
    classes: string;
    drag: LegacyRef<HTMLDivElement> | null;
    children: ReactElement<{ config: { componentType: string } }>[];
    config: ComposerElement;
    preview: PreviewMode | null;
}

const Chart = ({ id, parentId, classes, drag, children, config, preview }: Props) => {
    const { developerName, label } = config;

    const { setElementToDelete, dragDropElements } = useComposer();
    const { getComponent } = usePageEditor();

    const validated =
        !isNullOrEmpty(developerName) &&
        dragDropElements
            .filter((element) => element.parentId === id)
            .every((config) =>
                isComponentConfigured(
                    {
                        ...config,
                        componentType: config.componentType?.toUpperCase() as string,
                    },
                    getComponent,
                ),
            );

    const renderDataSet = () => {
        const { renderFn } = getComponent(children[0].props?.config.componentType);
        return renderFn({ componentType: children[0].props.config.componentType });
    };

    const headerClasses = classNames({
        'page-container-header': true,
        draggable: true,
        preview: !!preview,
    });

    const bodyClasses = classNames(classes, 'component-preview-chart');

    const componentTitle = developerName ? `Chart: ${developerName}` : 'New Chart';

    const isDraggable = !preview;

    return (
        <div
            id={id}
            // biome-ignore lint/a11y/useSemanticElements: Requires refactor
            role="button"
            tabIndex={-1}
            className="page-container-inner"
        >
            {parentId ? (
                <div className={headerClasses}>
                    <div ref={isDraggable ? drag : null} className="drag-handle">
                        <DotsSix size={32} />
                    </div>
                    <div title={componentTitle} className="page-element-title">
                        {componentTitle}
                    </div>
                    <div className="container-header-actions flex-child-right">
                        <ButtonIcon
                            className="header-icon-button"
                            title="Delete Chart"
                            onClick={() => setElementToDelete({ targetId: id })}
                        >
                            <Trash size={20} weight="fill" />
                        </ButtonIcon>
                    </div>
                </div>
            ) : null}
            <div className={bodyClasses}>
                {label ? <span className="page-component-preview-label">{label}</span> : null}
                {children.length === 0 ? 'Your chart must contain a dataset' : renderDataSet()}
            </div>
            {!validated && (
                <mark className="config-warning flex-child-right" data-testid="mark">
                    <Warning weight="fill" />
                </mark>
            )}
        </div>
    );
};
export default Chart;
