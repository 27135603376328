import * as Icons from '@phosphor-icons/react';
import { clone, isEmpty, isNil } from 'ramda';
import {
    createContext,
    useContext,
    useEffect,
    useReducer,
    useState,
    type ReactNode,
    type Reducer,
} from 'react';
import { NOTIFICATION_TYPES, TAB_TYPES } from '../../../constants';
import { getCustomPageComponents } from '../../../sources/customPageComponents';
import { getPage, savePage } from '../../../sources/page';
import translations from '../../../translations';
import type {
    AddNotification,
    ComponentRegistry,
    Page,
    PageCondition,
    PageEditorState,
    PreviewMode,
    Tab,
    ValueElementIdReferenceAPI,
} from '../../../types';
import type { AuthenticatedUser } from '../../../types/auth';
import { isNullOrEmpty } from '../../../utils/guard';
import {
    COMPONENT_GROUPS,
    COMPONENT_TYPE,
    RUNTIME_VERSION,
    COMPONENT_CONFIGURATION_OPTIONS as componentConfigurationOption,
} from '../constants';
import { componentRegistry } from '../registry';
import { filterComponentsToRender, initPage } from '../utils';
import PageEditorReducer, { type PageAction } from './PageEditorReducer';

interface PageEditorProviderContext {
    updatePage: (page: Page) => void;
    setPageTab: (tab: Tab) => void;
    state: PageEditorState;
    onSave: (updatedPage: Page, successMessage?: string) => Promise<Page | undefined>;
    addNotification: AddNotification;
    updateTab: (tab: Tab) => void;
    setModifiedPageConditions: (isModified: boolean) => void;
    setPageUser: (user: AuthenticatedUser) => void;
    setPreviewMode: (mode: PreviewMode | null) => void;
    setPageName: (name: string | null) => void;
    setShowPageConditions: (show: boolean) => void;
    setShowMetadataEditor: (show: boolean) => void;
    setPageConditions: (pageConditions: PageCondition[]) => void;
    onLoad: () => void;
    selectComponentSuggestValue: (elementReference: ValueElementIdReferenceAPI | null) => void;
    setStopComponentSuggest: () => void;
    getValidComponents: (contentType: string) => ComponentRegistry[];
    getComponent: (key: string | null) => ComponentRegistry;
    loadCustomPageComponents: () => void;
    clonePage: (page: Page) => void;
    toggleUnsavedChanges: (toggle: boolean) => void;
    isLoading: boolean;
    container: HTMLElement | null;
    componentRegistryList: ComponentRegistry[];
}

const Context = createContext<PageEditorProviderContext | undefined>(undefined);

interface PageEditorProviderProps {
    addNotification: AddNotification;
    updateTab: (tab: Tab) => void;
    initialState?: PageEditorState | null | undefined;
    containerId: string;
    children: ReactNode;
}

const defaultState = {
    preview: null,
    page: initPage(),
    showPageConditions: false,
    showMetadataEditor: false,

    // Used to make sure the user doesn't abandon a modified or incomplete page condition without
    // being warned about it. This is not the same as unsaved changes (showConfirmation).
    // A condition will toggle an unsaved change only after it has been applied which means it was
    // saved from the page condition context into the main page builder context's page metadata.
    modifiedPageCondition: false,
    showPageLoader: true,
    showCustomPageComponentsLoader: false,
    tab: null,
    user: null,
    showComponentSuggest: false,
    componentSuggestValue: null,
};

const PageEditorProvider = ({
    addNotification,
    updateTab,
    initialState,
    containerId,
    children,
}: PageEditorProviderProps) => {
    const [container, setContainer] = useState<HTMLElement | null>(null);
    useEffect(() => {
        const element = document.getElementById(containerId);
        setContainer(element);
    });

    const combinedInitialState =
        isNil(initialState) || isEmpty(initialState)
            ? defaultState
            : {
                  ...defaultState,
                  ...initialState,
              };

    const [state, dispatch] = useReducer<Reducer<PageEditorState, PageAction>>(
        PageEditorReducer,
        combinedInitialState,
    );

    const [combinedComponentRegistry, setCombinedComponentRegistry] = useState(componentRegistry);

    const componentRegistryList = filterComponentsToRender(combinedComponentRegistry);

    const update = (action: PageAction) => dispatch(action);

    const updatePage = (page: Page = initPage()) => update({ type: 'set_page', payload: page });

    const setModifiedPageConditions = (isModified: boolean) =>
        update({ type: 'set_modified_page_condition', payload: isModified });

    const setPageTab = (tab: Tab) => update({ type: 'set_page_tab', payload: tab });

    const setPageUser = (user: AuthenticatedUser) =>
        update({ type: 'set_page_user', payload: user });

    const setPreviewMode = (mode: PreviewMode | null) =>
        update({ type: 'set_page_preview', payload: mode });

    const setIsLoading = (isLoading: boolean) =>
        update({ type: 'set_show_page_loader', payload: isLoading });

    const setCustomComponentsIsLoading = (isLoading: boolean) =>
        update({ type: 'set_show_custom_component_loader', payload: isLoading });

    const setShowPageConditions = (show: boolean) =>
        update({ type: 'set_show_page_conditions_panel', payload: show });

    const setShowMetadataEditor = (show: boolean) =>
        update({ type: 'set_show_metadata_editor', payload: show });

    const setPageConditions = (pageConditions: PageCondition[]) => {
        update({ type: 'set_page_conditions', payload: pageConditions });
        toggleUnsavedChanges(true);
    };

    const setStartComponentSuggest = (payload: ValueElementIdReferenceAPI | null) =>
        update({ type: 'set_start_component_suggest', payload });

    const setStopComponentSuggest = () => update({ type: 'set_stop_component_suggest' });

    const selectComponentSuggestValue = (elementReference: ValueElementIdReferenceAPI | null) => {
        setStartComponentSuggest(elementReference);
    };

    const clonePage = (page: Page) => {
        onSave({ ...page, id: null }, translations.PAGE_clone_success);
    };

    const setPageName = (name: string | null) => {
        const updatedPage = {
            ...state.page,
            developerName: name,
        };
        const updatedTab = {
            ...state.tab,
            title: name,
            showConfirmation: true,
        } as Tab;

        updatePage(updatedPage);
        updateTab(updatedTab);
    };

    const toggleUnsavedChanges = (toggle: boolean) => {
        const updatedUnsavedChanges = isNil(toggle) ? !state?.tab?.showConfirmation : toggle;
        if (state?.tab?.showConfirmation !== updatedUnsavedChanges) {
            updateTab({
                ...state.tab,
                key: state?.tab?.key,
                showConfirmation: updatedUnsavedChanges,
            } as Tab);
        }
    };

    const onLoad = async () => {
        try {
            setIsLoading(true);

            const loadedPage = await getPage(state?.tab?.elementId || '');

            if (loadedPage) {
                updatePage(loadedPage);
                toggleUnsavedChanges(false);
            }
        } catch (error) {
            addNotification({
                type: 'error',
                message: `${translations.PAGE_BUILDER_unable_to_load_page}: ${(error as Error).toString()}`,
                isPersistent: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const onSave = async (
        updatedPage: Page,
        successMessage?: string,
    ): Promise<Page | undefined> => {
        try {
            const savedPage = await savePage(updatedPage);

            const key = state.tab?.key ?? '';

            const updatedTab = {
                ...state.tab,
                key,
                showConfirmation: false,
            } as Tab;

            updateTab(updatedTab);

            addNotification({
                type: NOTIFICATION_TYPES.success,
                message: successMessage ?? translations.PAGE_save_success,
                isPersistent: false,
            });
            return savedPage;
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: `${translations.PAGE_BUILDER_unable_to_save_page}: ${
                    (error as Error).message
                }`,
                isPersistent: true,
            });
        }

        return undefined;
    };

    const getValidComponents = (contentType: string): ComponentRegistry[] =>
        componentRegistryList.filter((component) =>
            component?.componentSuggestContentTypeFilter?.includes(contentType?.toUpperCase()),
        );

    const getComponent = (key: string | null) => {
        if (key !== null) {
            return (
                combinedComponentRegistry[key?.toUpperCase()] ??
                combinedComponentRegistry[COMPONENT_TYPE['UNKNOWN']]
            );
        }

        return combinedComponentRegistry[COMPONENT_TYPE['UNKNOWN']];
    };

    const loadCustomPageComponents = async () => {
        setCustomComponentsIsLoading(true);
        const customComponents: { [x: string]: ComponentRegistry } = {};
        try {
            const components = await getCustomPageComponents({
                page: 1,
                pageSize: -1,
                orderBy: 'developerName',
                orderDirection: 'ASC',
            });

            components.items.forEach((component) => {
                const mockComponent = clone(componentRegistry[COMPONENT_TYPE['UNKNOWN']]);

                const isScriptEmpty = isNullOrEmpty(component.scriptURL);
                const isLegacyScriptEmpty = isNullOrEmpty(component.legacyScriptURL);
                mockComponent.runtimeSupport =
                    !isScriptEmpty && isLegacyScriptEmpty
                        ? RUNTIME_VERSION['NEXT']
                        : isScriptEmpty && !isLegacyScriptEmpty
                          ? RUNTIME_VERSION['LEGACY']
                          : RUNTIME_VERSION['BOTH'];

                mockComponent.group = COMPONENT_GROUPS['CUSTOM'];
                mockComponent.type = component?.key?.toUpperCase();

                const iconKey =
                    // Remove these keys as we don't use them and their inclusion changes type inference
                    (
                        Object.keys(Icons).filter((key) => {
                            return ['IconContext', 'IconBase', 'SSR'].includes(key) === false;
                        }) as (keyof Omit<typeof Icons, 'IconContext' | 'IconBase' | 'SSR'>)[]
                    ).find((key) => {
                        return key === component.icon;
                    });

                // Key should never be undefined
                const ComponentIcon = Icons[iconKey!];

                if (ComponentIcon) {
                    mockComponent.ui.icon = <ComponentIcon />;
                }
                mockComponent.ui.caption = component.developerName!;
                mockComponent.ui.description = component.developerSummary!;
                mockComponent.configuration = [
                    ...(component.configurationEditors || []),
                    componentConfigurationOption['NAME'],
                ];
                mockComponent.renderFn = () => (
                    <span className="full-width component-preview">{component.developerName}</span>
                );
                if (
                    component.designTimeRenderType &&
                    componentRegistry[component.designTimeRenderType.toUpperCase()]
                ) {
                    mockComponent.renderFn =
                        componentRegistry[component.designTimeRenderType.toUpperCase()].renderFn;
                }
                if (component.designTimeImageURL) {
                    mockComponent.renderFn = () => (
                        <img
                            className="full-width component-preview custom-page-component-preview component-preview-image"
                            src={component.designTimeImageURL}
                            alt={mockComponent.ui.caption}
                        />
                    );
                }
                if (component.attributes && Object.entries(component.attributes).length > 0) {
                    mockComponent.attributes = Object.entries(component.attributes).map(
                        ([key, value]) => ({
                            label: value,
                            value: key,
                        }),
                    );
                }

                customComponents[mockComponent.type] = mockComponent;
            });
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: (error as Error).toString(),
                isPersistent: true,
            });
        } finally {
            setCombinedComponentRegistry({ ...componentRegistry, ...customComponents });
            setCustomComponentsIsLoading(false);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        if (!(isNil(state.tab) || isNil(state.user))) {
            const { id: currentPageId } = state.page;
            const { elementId: tabPageId, type: tabType } = state.tab;

            const isExistingPageFirstRender =
                tabPageId && tabPageId !== currentPageId && tabType !== TAB_TYPES.newPage;

            if (isExistingPageFirstRender) {
                onLoad();
            }

            const isNewPageFirstRender = tabType === TAB_TYPES.newPage && state.showPageLoader;

            if (isNewPageFirstRender) {
                setIsLoading(false);
            }

            if (isNewPageFirstRender || isExistingPageFirstRender) {
                loadCustomPageComponents();
            }
        }
    }, [state.tab, state.user]);

    const contextValue = {
        updateTab,
        state,
        setModifiedPageConditions,
        updatePage,
        addNotification,
        setPageTab,
        setPageUser,
        setPreviewMode,
        setPageName,
        setShowPageConditions,
        setShowMetadataEditor,
        setPageConditions,
        onLoad,
        onSave,
        selectComponentSuggestValue,
        setStopComponentSuggest,
        getValidComponents,
        getComponent,
        loadCustomPageComponents,
        clonePage,
        toggleUnsavedChanges,
        isLoading: state.showPageLoader || state.showCustomPageComponentsLoader,
        container,
        componentRegistryList,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const usePageEditor = (): PageEditorProviderContext => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('usePageEditor must be used within a PageEditorProvider');
    }
    return context;
};

export { PageEditorProvider, usePageEditor };
