import ConfigSection from '../../ConfigSection';
import FormGroup from '../../../../../../../generic/FormGroup';
import { COMPONENT_CONFIGURATION_LABELS } from '../../../../../../constants';
import type { ComposerElement } from '../../../../../../../../types';

interface Props {
    modifierOptions: { [key: string]: boolean };
    modifierValues: Partial<ComposerElement>;
    updateHelpInfo: (helpInfo: string) => void;
    updateHintValue: (hitValue: string) => void;
}

const HelpSection = ({
    modifierOptions,
    modifierValues,
    updateHelpInfo,
    updateHintValue,
}: Props) => {
    if (!(modifierOptions || modifierValues)) {
        return null;
    }

    const { hasHintValue } = modifierOptions ?? {};
    const { helpInfo, hintValue } = modifierValues;

    return (
        <ConfigSection dataTestId="help-section">
            {hasHintValue && (
                <FormGroup
                    label={COMPONENT_CONFIGURATION_LABELS['HINT_VALUE']}
                    htmlFor="component-hint-value"
                >
                    <input
                        className="form-control"
                        id="component-hint-value"
                        type="text"
                        value={hintValue || ''}
                        onChange={(event) => {
                            updateHintValue(event.target.value);
                        }}
                    />
                </FormGroup>
            )}

            <FormGroup
                label={COMPONENT_CONFIGURATION_LABELS['HELP_INFO']}
                htmlFor="component-help-info"
            >
                <textarea
                    className="form-control"
                    id="component-help-info"
                    value={helpInfo || ''}
                    onChange={(event) => {
                        updateHelpInfo(event.target.value);
                    }}
                />
            </FormGroup>
        </ConfigSection>
    );
};

export default HelpSection;
