import { type ReactNode, useEffect, useRef, useState } from 'react';
import { useFloating, flip, shift, arrow, offset, type Placement } from '@floating-ui/react-dom';

import './css/tooltip.less';
import classNames from 'classnames';

export type TriggerType = 'click' | 'hover' | 'focus';
interface Props {
    /** Element(s) to make the target of the tooltip. */
    children: ReactNode;
    /** The content of the tooltip, either simple text or some HTML. */
    tooltipContent: ReactNode;
    /** One or an array of one or more ways to trigger a tooltip, for example 'click', 'hover' or 'focus'. */
    trigger: TriggerType | TriggerType[];
    /** How long to wait after the open trigger before the tooltip is shown. */
    showDelay?: number;
    /** How long to wait after the close trigger before the tooltip is hidden. */
    hideDelay?: number;
    /** Whether the tooltip will begin to close immediately after opening when the 'click' trigger is used. */
    hideAterClick?: boolean;
    /** How far from the target element the tooltip will be. */
    clearance?: number;
    /** How far from the arrow will protrude from the tooltip. */
    arrowClearance?: number;
    /** Class to extend the basic CSS provided for the wrapper around the tooltip and children elements. */
    wrapperClass?: string;
    /** Where relative to the target the tooltip is placed. Accepts alignment by adding '-start' or '-end' */
    tooltipPlacement?: Placement;
    /** CSS class override for the tooltip. */
    tooltipClass?: string;
    /** CSS class override for the tooltip arrow. */
    tooltipArrowClass?: string;
    /** A fade in/out time or the tooltip. */
    fadeTime?: number;
    /** Allows the open state to be controlled from outside the component, if it is needed. */
    isOpenExternal?: boolean | null;
}

const ComponentWithTooltip = ({
    children,
    trigger,
    showDelay = 0,
    hideDelay = 0,
    hideAterClick = true,
    tooltipContent,
    clearance = 5,
    arrowClearance = 5,
    wrapperClass,
    tooltipPlacement = 'top',
    tooltipClass = 'tooltip-content',
    tooltipArrowClass = 'tooltip-arrow',
    fadeTime = 0,
    isOpenExternal = null,
}: Props) => {
    const arrowRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [opacity, setOpacity] = useState(0);
    const {
        x,
        y,
        reference,
        floating,
        strategy,
        placement,
        middlewareData: {
            arrow: { x: arrowX, y: arrowY } = {},
        },
    } = useFloating({
        placement: tooltipPlacement,
        strategy: 'fixed',
        middleware: [
            offset(clearance),
            flip(),
            shift({ padding: 5 }),
            arrow({ element: arrowRef }),
        ],
    });

    // Tracks the timers so they can be stopped if the trigger is cancelled.
    // For example, user hovers over something with a 2 second delay and then moves off before the timer finishes.
    let openTimer: number | undefined;
    let closeTimer: number | undefined;

    const fadeIn = () => {
        setOpen(true);
        setOpacity(1);
    };

    // Allow the fade animation to play before removing the tooltip from the DOM
    const fadeOut = () => {
        setOpacity(0);

        window.setTimeout(() => {
            setOpen(false);
        }, fadeTime * 1000); // Convert to ms
    };

    // For if the component is being controlled from outside by passing a prop in.
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (isOpenExternal !== null) {
            if (isOpenExternal) {
                fadeIn();
                return;
            }
            fadeOut();
        }
    }, [isOpenExternal]);

    // The 'static side', the axis that doesn't need to be changed, has it's positioning left empty.
    // This function gets the opposite side so it can be used to place the arrow. For example:
    // placement === top, so the static side will be bottom and the left is dynamically set by Floating UI.
    const getStaticSideFromPlacement = (placement: Placement) => {
        // Split by - as there are alignment versions of each direction ('top-start', etc.)
        const staticPosition = placement.split('-')[0];

        if (staticPosition) {
            const staticSide = {
                top: 'bottom',
                right: 'left',
                bottom: 'top',
                left: 'right',
            }[staticPosition];

            return staticSide;
        }

        return '';
    };

    const staticSide = getStaticSideFromPlacement(placement);

    // One side will be set and the other is intentionally left invalid so the static side can override the position.
    const arrowPosition = {
        left: `${(arrowX ?? '').toString()}px`,
        top: `${(arrowY ?? '').toString()}px`,
    };

    const triggerContains = (type: TriggerType) => trigger.includes(type);

    const handleClick = () => {
        if (triggerContains('click')) {
            if (open) {
                if (openTimer) {
                    window.clearTimeout(openTimer);
                }

                closeTimer = window.setTimeout(() => {
                    fadeOut();
                }, hideDelay);
            } else {
                if (closeTimer) {
                    window.clearTimeout(closeTimer);
                }

                openTimer = window.setTimeout(() => {
                    fadeIn();

                    if (hideAterClick) {
                        window.setTimeout(() => {
                            fadeOut();
                        }, hideDelay);
                    }
                }, showDelay);
            }
        }
    };

    const handleMouseEnter = () => {
        if (triggerContains('hover')) {
            if (closeTimer) {
                window.clearTimeout(closeTimer);
            }

            openTimer = window.setTimeout(() => {
                fadeIn();
            }, showDelay);
        }
    };

    const handleMouseLeave = () => {
        if (triggerContains('hover')) {
            if (openTimer) {
                window.clearTimeout(openTimer);
            }

            closeTimer = window.setTimeout(() => {
                fadeOut();
            }, hideDelay);
        }
    };

    const handleFocus = () => {
        if (triggerContains('focus')) {
            if (closeTimer) {
                window.clearTimeout(closeTimer);
            }

            openTimer = window.setTimeout(() => {
                fadeIn();
            }, showDelay);
        }
    };

    const handleBlur = () => {
        if (triggerContains('focus')) {
            if (openTimer) {
                window.clearTimeout(openTimer);
            }

            closeTimer = window.setTimeout(() => {
                fadeOut();
            }, hideDelay);
        }
    };

    const opacityTransition = `opacity ${fadeTime}s ease-in-out`;

    const wrapperClasses = classNames('tooltip-wrapper', wrapperClass);

    return (
        <>
            <div
                className={wrapperClasses}
                onClick={handleClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onFocus={handleFocus}
                onBlur={handleBlur}
                ref={reference}
                tabIndex={-1}
                // biome-ignore lint/a11y/useSemanticElements: Requires refactor
                role="button"
                onKeyPress={undefined}
            >
                {children}
            </div>

            {open ? (
                <div
                    role="tooltip"
                    ref={floating}
                    className={tooltipClass}
                    style={{
                        position: strategy,
                        top: y ?? 0,
                        left: x ?? 0,
                        width: 'max-content',
                        opacity: opacity,
                        transition: opacityTransition,
                    }}
                >
                    {tooltipContent}
                    <div
                        ref={arrowRef}
                        className={tooltipArrowClass}
                        style={{
                            left: arrowPosition.left,
                            top: arrowPosition.top,
                            [staticSide ?? '']: `-${arrowClearance}px`,
                        }}
                    />
                </div>
            ) : null}
        </>
    );
};

export default ComponentWithTooltip;
