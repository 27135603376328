import GenericModal from '../../../../generic/modal/GenericModal';
import ButtonDefault from '../../../../buttons/ButtonDefault';
import ButtonPrimary from '../../../../buttons/ButtonPrimary';
import { CheckCircle } from '@phosphor-icons/react';
import classNames from 'classnames';
import { COMPONENT_TYPE, PAGE_ELEMENT_TYPES } from '../../../constants';
import type {
    column,
    ComponentRegistry,
    ComposerElement,
    ValueElementIdReferenceAPI,
} from '../../../../../types';
import { columnTypes } from './constants';
import translations from '../../../../../translations';
import ButtonLoading from '../../../../buttons/ButtonLoading';
import RuntimeMarker from '../composer/misc/RuntimeMarker';
import { usePageEditor } from '../../PageEditorProvider';
import { useComposer } from '../composer/ComposerProvider';
import { ExLoader, LoaderSize, LoaderVariant } from '@boomi/exosphere';
import { getValueName, formatAsValue } from '../../../../values/selector/value-selector-utils';

const ComponentSuggestOptions = ({
    selectedComponent,
    suggestedComponents,
    calculatedSuggestedType,
    neuralNetworkSuggestedType,
    setSelectedComponent,
    goToMultipleColumnsScreen,
    goToSingleColumnScreen,
    goToTilesScreen,
    goToChartsScreen,
    mainContainer,
    order,
    isLoading,
}: {
    selectedComponent: ComponentRegistry | undefined;
    suggestedComponents: ComponentRegistry[];
    calculatedSuggestedType: string | null;
    neuralNetworkSuggestedType: string | undefined;
    setSelectedComponent: React.Dispatch<React.SetStateAction<ComponentRegistry | undefined>>;
    goToMultipleColumnsScreen: () => void;
    goToSingleColumnScreen: () => void;
    goToTilesScreen: () => void;
    goToChartsScreen: () => void;
    mainContainer: string;
    order: number;
    isLoading: boolean;
}) => {
    const { state, container, setStopComponentSuggest } = usePageEditor();
    const { onPageElementDrop } = useComposer();
    const componentSuggestValue = state.componentSuggestValue as ValueElementIdReferenceAPI;

    const createComponent = () => {
        if (!selectedComponent) {
            return;
        }

        if (selectedComponent.componentSuggestColumnType === columnTypes.multiple) {
            goToMultipleColumnsScreen();
            return;
        }
        if (selectedComponent.componentSuggestColumnType === columnTypes.single) {
            goToSingleColumnScreen();
            return;
        }

        let partialElement = {} as Partial<ComposerElement>;

        switch (selectedComponent.type) {
            case COMPONENT_TYPE['PRESENTATION']: {
                partialElement = {
                    developerName: `"${getValueName(componentSuggestValue)}"`,
                    content: formatAsValue(
                        componentSuggestValue.developerName,
                        componentSuggestValue.typeElementPropertyDeveloperName,
                    ),
                };
                break;
            }
            case COMPONENT_TYPE['TILES']: {
                goToTilesScreen();
                return;
            }
            case COMPONENT_TYPE['CHARTBAR']:
            case COMPONENT_TYPE['CHARTLINE']:
            case COMPONENT_TYPE['CHARTPIE']:
            case COMPONENT_TYPE['CHARTDOUGHNUT']:
            case COMPONENT_TYPE['CHARTPOLAR']: {
                goToChartsScreen();
                return;
            }
            default:
                partialElement = {
                    developerName: `"${getValueName(componentSuggestValue)}"`,
                    valueElementValueBindingReferenceId: {
                        id: componentSuggestValue.id,
                        typeElementPropertyId:
                            componentSuggestValue.typeElementPropertyId as string,
                        command: null,
                        relativeUnit: null,
                    },
                };
        }

        onPageElementDrop({
            id: null,
            targetId: mainContainer,
            order,
            pageElementType: PAGE_ELEMENT_TYPES['component'],
            componentType: selectedComponent.type.toLowerCase(),
            select: true,
            partialElement,
        });
        setStopComponentSuggest();
    };

    const renderPreviewComponent = (
        component: ComponentRegistry,
        RenderComponent: (props: {
            columns: column[];
            label?: string;
            content: string;
        }) => JSX.Element,
        title: string,
        name: string | JSX.Element,
        key: string = component.type,
    ) => (
        <button
            className="component-suggestion"
            data-testid="component-suggestion"
            key={key}
            title={title}
            onClick={() => setSelectedComponent(component)}
            type="button"
        >
            <div className="render">
                <div className="nextgen-page-builder active composer">
                    <div className="composer">
                        <RenderComponent
                            columns={[
                                {
                                    typeElementPropertyDeveloperName: 'Column 1',
                                    order: 0,
                                    typeElementPropertyId: '1',
                                    isDisplayValue: true,
                                    label: 'Preview Column',
                                    boundTypeElementPropertyId: null,
                                    isBound: false,
                                    isEditable: false,
                                    componentType: null,
                                    typeElementPropertyToDisplayId: null,
                                    isPinned: false,
                                },
                                {
                                    typeElementPropertyDeveloperName: 'Column 2',
                                    order: 1,
                                    typeElementPropertyId: '2',
                                    isDisplayValue: true,
                                    label: 'Preview Column',
                                    boundTypeElementPropertyId: null,
                                    isBound: false,
                                    isEditable: false,
                                    componentType: null,
                                    typeElementPropertyToDisplayId: null,
                                    isPinned: false,
                                },
                            ]}
                            label="Preview Label"
                            content="<h1>Preview Header</h1><p>Preview Paragraph</p><ul><li>Preview List Item</li></ul>"
                        />
                    </div>
                </div>
                <CheckCircle
                    className={classNames({
                        check: true,
                        hidden: selectedComponent?.type !== component.type,
                    })}
                    size={32}
                    weight="fill"
                />
                <RuntimeMarker runtime={component.runtimeSupport} miniMarker={false} />
            </div>
            <div className="text">{name}</div>
        </button>
    );

    const renderNeuralNetworkComponent = () => {
        const neuralNetworkComponent = suggestedComponents.find(
            (component) => component.type === neuralNetworkSuggestedType,
        );

        const loadingName = 'Generating AI suggestion...';

        if (isLoading) {
            return (
                <button
                    className="component-suggestion"
                    data-testid="component-suggestion"
                    key="neural-network-component"
                    title={loadingName}
                    type="button"
                >
                    <div className="render loading">
                        <ExLoader size={LoaderSize.XLARGE} variant={LoaderVariant.RADAR} />
                        {loadingName}
                    </div>
                    <div className="text"> </div>
                </button>
            );
        }

        if (!neuralNetworkComponent) {
            return null;
        }

        const RenderComponent = neuralNetworkComponent.renderFn;
        const title = `${neuralNetworkComponent.ui.caption} (AI Suggested)`;
        const name = (
            <>
                {`${neuralNetworkComponent.ui.caption} `}
                <span className="suggest-info">(AI Suggested)</span>
            </>
        );

        return renderPreviewComponent(
            neuralNetworkComponent,
            RenderComponent,
            title,
            name,
            'neural-network-component',
        );
    };

    return (
        <GenericModal
            container={container}
            show
            className="config-modal component-suggestion-modal"
            onHide={setStopComponentSuggest}
            animation={false}
            title={translations.COMPONENT_SUGGEST_suggest_options_view_title}
            bodyClassName="flex"
            renderBody={() => {
                if (suggestedComponents.length === 0) {
                    return <>{translations.COMPONENT_SUGGEST_no_suggestions}</>;
                }

                return (
                    <div className="component-suggestion-list">
                        {renderNeuralNetworkComponent()}
                        {suggestedComponents.map((suggestedComponent) => {
                            const RenderComponent = suggestedComponent.renderFn;
                            const title =
                                suggestedComponent.ui.caption +
                                (calculatedSuggestedType === suggestedComponent.type
                                    ? ' (Recommended)'
                                    : '');
                            const name =
                                calculatedSuggestedType === suggestedComponent.type ? (
                                    <>
                                        {`${suggestedComponent.ui.caption} `}
                                        <span className="suggest-info">(Recommended)</span>
                                    </>
                                ) : (
                                    suggestedComponent.ui.caption
                                );

                            return renderPreviewComponent(
                                suggestedComponent,
                                RenderComponent,
                                title,
                                name,
                            );
                        })}
                    </div>
                );
            }}
            renderFooter={() => {
                let primaryButton = null;
                if (isLoading) {
                    primaryButton = <ButtonLoading className="btn btn-primary" isLoading={true} />;
                }
                if (selectedComponent) {
                    primaryButton = (
                        <ButtonPrimary
                            onClick={createComponent}
                            title={`Create ${selectedComponent.ui.caption} component`}
                        >
                            {componentSuggestValue.contentType === 'ContentList'
                                ? `Configure ${selectedComponent.ui.caption} component`
                                : `Create ${selectedComponent.ui.caption} component`}
                        </ButtonPrimary>
                    );
                }

                return (
                    <>
                        <ButtonDefault title="Cancel" onClick={setStopComponentSuggest}>
                            Cancel
                        </ButtonDefault>
                        {primaryButton}
                    </>
                );
            }}
        />
    );
};

export default ComponentSuggestOptions;
