import type {
    ComposerElement,
    ListFilter,
    Page,
    FilterType,
    ObjectDataRequest,
    ValueElementIdAPI,
    ComponentRegistry,
    PageContainer,
} from '../../types';
import { merge, concat, difference, type, isNil, path, isEmpty } from 'ramda';
import { componentRegistry } from './registry';
import {
    COMPONENT_CONFIGURATION_OPTIONS,
    DATA_SOURCE,
    CONTAINER_TYPE,
    COMPONENT_TYPE,
    COMPONENT_CONFIGURATION_PATH,
} from './constants';
import type { ContainerType } from './components/dnd-utils';
import { isNullOrEmpty } from '../../utils/guard';

const initialComponent = {
    attributes: null,
    className: null,
    colSpan: 0,
    column: 0,
    columns: null,
    componentType: null,
    content: '',
    developerName: null,
    fileDataRequest: null,
    height: 0,
    helpInfo: null,
    hintValue: null,
    imageUri: '',
    isEditable: true,
    isMultiSelect: false,
    isRequired: false,
    isSearchable: false,
    isSearchableDisplayColumns: true,
    label: null,
    maxSize: null,
    objectDataRequest: null,
    order: 1,
    pageContainerDeveloperName: null,
    pageContainerId: null,
    row: 0,
    rowSpan: 0,
    size: 25,
    tags: null,
    validations: [],
    valueElementDataBindingReferenceId: null,
    valueElementValueBindingReferenceId: null,
    width: 0,
    id: null,
    pageContainers: null,
};

const initialContainer = {
    developerName: null,
    id: null,
    containerType: null,
    label: null,
    order: 0,
    tags: null,
    pageContainers: null,
    attributes: null,
} as ComposerElement;

const pageConfig = {
    elementType: 'PAGE_LAYOUT',
    id: null,
    tags: null,
    label: null,
    attributes: null,
    dateCreated: null,
    dateModified: null,
    developerName: 'New Page',
    developerSummary: null,
    // When no components the default is null not []! This is based on classic pages.
    pageComponents: null,
    // The CSS grid is the only page container in the new page builder.
    pageContainers: [
        {
            developerName: 'Main Container',
            id: '00000000-0000-0000-0000-000000000000',
            containerType: 'VERTICAL_FLOW' as ContainerType,
            label: null,
            order: 0,
            tags: null,
            pageContainers: null,
            attributes: null,
            className: null,
        },
    ] as PageContainer[],
    saveHiddenInputs: false,
    pageConditions: null,
    stopConditionsOnFirstTrue: false,
    updateByName: false,
    whoCreated: null,
    whoModified: null,
    whoOwner: null,
};

export const formatAttributes = (attributes: { [key: string]: string } | null | undefined) =>
    !isNullOrEmpty(attributes) && attributes !== undefined && attributes !== null
        ? Object.entries(attributes)
              .filter(([key]) => !key.startsWith('$')) // filter "system attributes"
              .map(([key, value]) => ({
                  key,
                  value,
              }))
        : [];

export const isComponentConfigured = (
    config: ComposerElement,
    getComponent: (key: string | null) => ComponentRegistry,
) => {
    // Check config only against options that are relevant to the specific component type as not all
    // options are really required on all components.
    const { componentType } = config;
    const { hasDataSourceList, hasDataSourceConnector } = hasConfigurationOptions(
        getComponent(componentType).configuration,
    );
    const requiredOptions = getComponent(componentType).required;

    let notConfiguredOptions = requiredOptions
        .map((option) => {
            const value = path(COMPONENT_CONFIGURATION_PATH[option], config);
            const notConfigured = isNil(value) || isEmpty(value);
            return notConfigured ? option : null;
        })
        // We don't care about nulls (configured options) so get rid of them.
        .filter((option) => option !== null);

    // Check the data source config to make sure that data source list is not flagged as not
    // configured if we already have a data source connector configured, and vice-versa.
    if (
        hasDataSourceList &&
        hasDataSourceConnector &&
        // Either list or connector is configured.
        !(
            notConfiguredOptions.includes(COMPONENT_CONFIGURATION_OPTIONS['DATA_SOURCE_LIST']) &&
            notConfiguredOptions.includes(COMPONENT_CONFIGURATION_OPTIONS['DATA_SOURCE_CONNECTOR'])
        )
    ) {
        // List is not configured but connector is, so we don't care about list in this case.
        if (
            notConfiguredOptions.includes(COMPONENT_CONFIGURATION_OPTIONS['DATA_SOURCE_LIST']) &&
            notConfiguredOptions.includes(COMPONENT_CONFIGURATION_OPTIONS['DATA_SOURCE_LIST_VALUE'])
        ) {
            notConfiguredOptions = notConfiguredOptions.filter(
                (option) =>
                    option !== COMPONENT_CONFIGURATION_OPTIONS['DATA_SOURCE_LIST'] &&
                    option !== COMPONENT_CONFIGURATION_OPTIONS['DATA_SOURCE_LIST_VALUE'],
            );
        }
        // Connector is not configured but list is, so we don't care about connector in this case.
        if (
            notConfiguredOptions.includes(
                COMPONENT_CONFIGURATION_OPTIONS['DATA_SOURCE_CONNECTOR'],
            ) &&
            notConfiguredOptions.includes(
                COMPONENT_CONFIGURATION_OPTIONS['DATA_SOURCE_CONNECTOR_TYPE'],
            ) &&
            notConfiguredOptions.includes(
                COMPONENT_CONFIGURATION_OPTIONS['DATA_SOURCE_CONNECTOR_BINDING'],
            )
        ) {
            notConfiguredOptions = notConfiguredOptions.filter(
                (option) =>
                    option !== COMPONENT_CONFIGURATION_OPTIONS['DATA_SOURCE_CONNECTOR'] &&
                    option !== COMPONENT_CONFIGURATION_OPTIONS['DATA_SOURCE_CONNECTOR_TYPE'] &&
                    option !== COMPONENT_CONFIGURATION_OPTIONS['DATA_SOURCE_CONNECTOR_BINDING'],
            );
        }
    }

    const isConfigurationComplete = notConfiguredOptions.length === 0;

    return isConfigurationComplete;
};

export const resize = (initialXaxisValue: number) => ({
    MIN_RIGHT_SIDEBAR_VW: 30,
    MAX_RIGHT_SIDEBAR_VW: 50,
    xAxisValue: initialXaxisValue,
    isDragging: false,
    getViewportWidthOfPanel: (
        pageEditorContainerWidth: number,
        xAxisValue: number,
        viewPortWidth: number,
    ) => (100 * (pageEditorContainerWidth - xAxisValue)) / viewPortWidth,
    onMouseDown: function (x: number) {
        this.isDragging = true;
        this.xAxisValue = x;
    },
    onMouseUp: function () {
        this.isDragging = false;
    },
    onMouseMove: function (x: number, pageEditorContainerWidth: number, viewPortWidth: number) {
        this.xAxisValue = x;
        if (this.isDragging) {
            const newXaxisValue = this.getViewportWidthOfPanel(
                pageEditorContainerWidth,
                this.xAxisValue,
                viewPortWidth,
            );

            if (
                !(
                    newXaxisValue <= this.MIN_RIGHT_SIDEBAR_VW ||
                    newXaxisValue >= this.MAX_RIGHT_SIDEBAR_VW
                )
            ) {
                return newXaxisValue;
            }

            return null;
        }

        return null;
    },
});

export const resizeConfigPanel = (
    rightSideBar: HTMLElement,
    resizeButton: HTMLElement,
    pageEditorContainer: HTMLElement,
) => {
    if (rightSideBar && resizeButton && pageEditorContainer) {
        const documentBody = document.getElementsByTagName('body')[0];

        const xAxisValue =
            rightSideBar.getBoundingClientRect().width + resizeButton.getBoundingClientRect().width;

        const configPanel = resize(xAxisValue);

        resizeButton.addEventListener('mousedown', (e) => {
            configPanel.onMouseDown(e.x);
        });

        pageEditorContainer.addEventListener('mousemove', (e) => {
            const pageEditorContainerWidth = pageEditorContainer.getBoundingClientRect().width;
            const sidebarWidth = document
                .getElementsByClassName('sidebar-admin')[0]
                .getBoundingClientRect().width;

            const newWidth = configPanel.onMouseMove(
                e.x - sidebarWidth,
                pageEditorContainerWidth,
                document.body.clientWidth,
            );

            if (newWidth !== null) {
                rightSideBar.style.width = `${newWidth}vw`;
                resizeButton.style.borderLeft = '3px solid #8aa1ac';
                documentBody.style.cursor = 'col-resize';
            }
        });

        pageEditorContainer.addEventListener('mouseup', () => {
            configPanel.onMouseUp();
            resizeButton.style.removeProperty('border-left');
            documentBody.style.removeProperty('cursor');
        });
    }
};

export const filterComponentsToRender = (components: { [x: string]: ComponentRegistry }) =>
    Object.values(components).filter((component) => {
        switch (component.type) {
            case COMPONENT_TYPE['UNKNOWN']:
                return false;
            default:
                return !!component;
        }
    });

export const hasConfigurationOptions = (options: string[] = []) => ({
    hasName: options.includes(COMPONENT_CONFIGURATION_OPTIONS['NAME']),
    hasLabel: options.includes(COMPONENT_CONFIGURATION_OPTIONS['LABEL']),
    hasContent: options.includes(COMPONENT_CONFIGURATION_OPTIONS['CONTENT']),
    hasImageUri: options.includes(COMPONENT_CONFIGURATION_OPTIONS['IMAGE_URI']),
    hasWidth: options.includes(COMPONENT_CONFIGURATION_OPTIONS['WIDTH']),
    hasHeight: options.includes(COMPONENT_CONFIGURATION_OPTIONS['HEIGHT']),
    hasEditable: options.includes(COMPONENT_CONFIGURATION_OPTIONS['EDITABLE']),
    hasMultiselect: options.includes(COMPONENT_CONFIGURATION_OPTIONS['MULTISELECT']),
    hasSearchable: options.includes(COMPONENT_CONFIGURATION_OPTIONS['SEARCHABLE']),
    hasSearchableDisplayColumns: options.includes(
        COMPONENT_CONFIGURATION_OPTIONS['SEARCHABLEDISPLAYCOLUMNS'],
    ),
    hasStateValue: options.includes(COMPONENT_CONFIGURATION_OPTIONS['STATE_VALUE']),
    // data source should either be list or connector
    hasDataSource:
        options.includes(COMPONENT_CONFIGURATION_OPTIONS['DATA_SOURCE_LIST']) ||
        options.includes(COMPONENT_CONFIGURATION_OPTIONS['DATA_SOURCE_CONNECTOR']),
    hasDataSourceList: options.includes(COMPONENT_CONFIGURATION_OPTIONS['DATA_SOURCE_LIST']),
    hasDataSourceConnector: options.includes(
        COMPONENT_CONFIGURATION_OPTIONS['DATA_SOURCE_CONNECTOR'],
    ),
    // has to have data source connector to be able to have the filter
    hasDataSourceFilter:
        options.includes(COMPONENT_CONFIGURATION_OPTIONS['DATA_SOURCE_CONNECTOR']) &&
        options.includes(COMPONENT_CONFIGURATION_OPTIONS['DATA_SOURCE_FILTER']),
    hasDataSource2: options.includes(COMPONENT_CONFIGURATION_OPTIONS['DATA_SOURCE_2']),
    hasDataPresentation: options.includes(COMPONENT_CONFIGURATION_OPTIONS['DATA_PRESENTATION']),
    hasFileDataSource: options.includes(COMPONENT_CONFIGURATION_OPTIONS['FILE_DATA_SOURCE']),
    hasHintValue: options.includes(COMPONENT_CONFIGURATION_OPTIONS['HINT_VALUE']),
    hasSource: options.includes(COMPONENT_CONFIGURATION_OPTIONS['SOURCE']),
    hasAutoUpload: options.includes(COMPONENT_CONFIGURATION_OPTIONS['AUTO_UPLOAD']),
    hasEditableColumns: options.includes(COMPONENT_CONFIGURATION_OPTIONS['EDITABLE_COLUMNS']),
    hasSortableColumns: options.includes(COMPONENT_CONFIGURATION_OPTIONS['EDITABLE_COLUMNS']),
    hasCsvExportButton: options.includes(COMPONENT_CONFIGURATION_OPTIONS['EXPORT_CSV_BUTTON']),
    hasCsvFileName: options.includes(COMPONENT_CONFIGURATION_OPTIONS['CSV_FILE_NAME']),
    canUseCustomComponent: options.includes(
        COMPONENT_CONFIGURATION_OPTIONS['COLUMN_COMPONENT_TYPE'],
    ),
    canPinColumns: options.includes(COMPONENT_CONFIGURATION_OPTIONS['PIN_COLUMNS']),
});

export const requiredConfigurationOptions = (required: string[] = []) => ({
    isNameRequired: required.includes(COMPONENT_CONFIGURATION_OPTIONS['NAME']),
    isLabelRequired: required.includes(COMPONENT_CONFIGURATION_OPTIONS['LABEL']),
    isContentRequired: required.includes(COMPONENT_CONFIGURATION_OPTIONS['CONTENT']),
    isImageUriRequired: required.includes(COMPONENT_CONFIGURATION_OPTIONS['IMAGE_URI']),
    isWidthRequired: required.includes(COMPONENT_CONFIGURATION_OPTIONS['WIDTH']),
    isHeightRequired: required.includes(COMPONENT_CONFIGURATION_OPTIONS['HEIGHT']),
    isEditableRequired: required.includes(COMPONENT_CONFIGURATION_OPTIONS['EDITABLE']),
    isMultiselectRequired: required.includes(COMPONENT_CONFIGURATION_OPTIONS['MULTISELECT']),
    isSearchableRequired: required.includes(COMPONENT_CONFIGURATION_OPTIONS['SEARCHABLE']),
    isStateValueRequired: required.includes(COMPONENT_CONFIGURATION_OPTIONS['STATE_VALUE']),
    isDataSourceRequired:
        required.includes(COMPONENT_CONFIGURATION_OPTIONS['DATA_SOURCE_LIST']) ||
        required.includes(COMPONENT_CONFIGURATION_OPTIONS['DATA_SOURCE_CONNECTOR']),
    isDataSourceFilterRequired:
        required.includes(COMPONENT_CONFIGURATION_OPTIONS['DATA_SOURCE_CONNECTOR']) &&
        required.includes(COMPONENT_CONFIGURATION_OPTIONS['DATA_SOURCE_FILTER']),
    isDataPresentationRequired: required.includes(
        COMPONENT_CONFIGURATION_OPTIONS['DATA_PRESENTATION'],
    ),
    isSourceRequired: required.includes(COMPONENT_CONFIGURATION_OPTIONS['SOURCE']),
});

export const determineSourceType = (config: ObjectDataRequest | ValueElementIdAPI | null) => {
    const keys = type(config) === 'Object' && config !== null ? Object.keys(config) : null;
    const uniqueListKeys = ['elementMeta', 'id'];
    const uniqueConnectorKeys = ['listFilter', 'typeElementBindingId'];

    const isListConfig = !!keys?.some((val) => uniqueListKeys.includes(val));
    const isConnectorConfig = !!keys?.some((val) => uniqueConnectorKeys.includes(val));

    return isListConfig ? DATA_SOURCE['LIST'] : isConnectorConfig ? DATA_SOURCE['CONNECTOR'] : null;
};

export const isListSourceConfigured = (source: ValueElementIdAPI | null) => {
    const isConfigured = !isNil(source?.id);

    return isConfigured;
};

export const isConnectorSourceConfigured = (source: ObjectDataRequest) => {
    const isConfigured = !(isNil(source?.typeElementBindingId) || isNil(source?.typeElementId));

    return isConfigured;
};

export const initPage = (partialPage: Partial<Page> = {}): Page => ({
    ...pageConfig,
    ...partialPage,
    dateCreated: new Date().toISOString(),
    dateModified: new Date().toISOString(),
});

export const calcContextMenuPosition = (
    menuId: string,
    posX: number,
    posY: number,
): { top: number; left: number } => {
    const composer = document.querySelector('.composer')?.getBoundingClientRect();
    const menu = document.getElementById(menuId)?.getBoundingClientRect();

    if (menu && composer) {
        const tooLow = posY + menu.height > composer.bottom;
        const tooFar = posX + menu.width > composer.right;

        const top = tooLow ? posY - menu.height : posY;

        const left = tooFar ? posX - menu.width : posX;

        return { top, left };
    }

    return { top: 0, left: 0 };
};

export const componentDataSourceFilter = (
    config: ListFilter | null,
): { filter: FilterType; isConfigured: boolean | undefined } => {
    const filterConfig = config;

    const usingNoFilter = !filterConfig;
    const usingUniqueFilter = filterConfig?.filterId;
    const usingWhereFilter = filterConfig?.where;
    const usingOrdering =
        filterConfig?.orderByTypeElementPropertyId || filterConfig?.orderByDirectionType;

    if (usingNoFilter) {
        return {
            filter: 'DO_NOT_FILTER',
            isConfigured: true,
        };
    }

    if (usingUniqueFilter) {
        return {
            filter: 'FILTER_BY_UNIQUE',
            isConfigured: !isNullOrEmpty(filterConfig?.filterId?.id),
        };
    }

    // WHERE filtering can be configured in four ways, just WHERE conditions, just ordering,
    // both or none. All is well if we configure both (WHERE + ordering) or just one of the two.
    // If none of them are configured then WHERE filter config is considered invalid.

    if (usingWhereFilter && usingOrdering) {
        return {
            filter: 'FILTER_BY_WHERE',
            isConfigured:
                !isNullOrEmpty(filterConfig.where) &&
                filterConfig?.where?.every((condition) => !isNullOrEmpty(condition)) &&
                filterConfig?.where.every(
                    (condition) => !isNullOrEmpty(condition.valueElementToReferenceId?.id),
                ) &&
                !!filterConfig?.orderByTypeElementPropertyId &&
                !!filterConfig?.orderByDirectionType,
        };
    }

    if (usingWhereFilter) {
        return {
            filter: 'FILTER_BY_WHERE',
            isConfigured:
                !isNullOrEmpty(filterConfig.where) &&
                filterConfig?.where?.every((condition) => !isNullOrEmpty(condition)) &&
                filterConfig?.where.every(
                    (condition) => !isNullOrEmpty(condition.valueElementToReferenceId?.id),
                ),
        };
    }

    if (usingOrdering) {
        return {
            filter: 'FILTER_BY_WHERE',
            isConfigured:
                !!filterConfig?.orderByTypeElementPropertyId &&
                !!filterConfig?.orderByDirectionType,
        };
    }

    return {
        filter: 'DO_NOT_FILTER',
        isConfigured: true,
    };
};

const getContainerOffspring = (pageElements: ComposerElement[], parent: string) => {
    const pageElementsToRemove = [] as ComposerElement[];
    pageElements.forEach((pageElement) => {
        if (pageElement.parentId === parent) {
            const children = getContainerOffspring(pageElements, pageElement.id as string);
            if (children.length) {
                children.forEach((child) => {
                    pageElementsToRemove.push(child);
                });
            }
            pageElementsToRemove.push(pageElement);
        }
    });
    return pageElementsToRemove;
};

export const createNewComponent = (partialElement: Partial<ComposerElement>): ComposerElement => {
    if (partialElement) {
        const componentType = (partialElement.componentType ?? '').toUpperCase();
        const componentRegistryItem = componentRegistry[componentType];
        const componentTypeBase = componentRegistryItem?.base ?? {};
        return {
            ...initialComponent,
            ...componentTypeBase,
            ...partialElement,
        };
    }
    return initialComponent;
};

export const createNewContainer = (partialElement: Partial<ComposerElement>): ComposerElement => {
    if (partialElement) {
        return {
            ...initialContainer,
            ...partialElement,
        };
    }
    return initialComponent;
};

export const reOrderPageElements = (
    pageElements: ComposerElement[],
    alteredPageElement: Partial<ComposerElement>,
    id: string | null,
    parent: string | null | undefined,
    order: number,
    isCurrent: boolean,
    isRemoving = false,
): ComposerElement[] => {
    const parentId = isCurrent ? alteredPageElement.parentId : parent;

    const pageElementSiblings = pageElements
        .filter((pageElement) => pageElement.parentId === parentId && pageElement.id !== id)
        .sort((a, b) => a.order - b.order);

    // We only want to insert the altered page element if the page element
    // is just being reordered within it's current parent element
    // or if the element is being moved into a new parent
    if (!isCurrent || (isCurrent && !parent)) {
        pageElementSiblings.splice(order, 0, alteredPageElement as ComposerElement);
    }

    const reOrdered = pageElementSiblings.map((pageElement, index) => {
        const changes = {
            parentId,
            order: index,
        };
        return merge(pageElement, changes);
    });

    const mergedPageContainers = concat(
        reOrdered,
        pageElements.filter((pageElement) => pageElement.parentId !== parentId),
    );

    // Page container has just been dropped within its current parent
    // or has been added to or removed from the page canvas
    if ((!parent && isCurrent) || (parent && !isCurrent) || isRemoving) {
        return mergedPageContainers;
    }

    // The element has been dropped from an existing parent into a new parent
    return reOrderPageElements(mergedPageContainers, alteredPageElement, id, parent, order, false);
};

export const removePageElement = (pageElements: ComposerElement[], id: string) => {
    const pageElementToRemove = pageElements.find(
        (pageElement) => pageElement.id === id,
    ) as ComposerElement;

    const offspringToRemove = getContainerOffspring(pageElements, pageElementToRemove.id as string);

    const pageElementsWithSpecificOffspringRemoved = difference(pageElements, offspringToRemove);

    return reOrderPageElements(
        pageElementsWithSpecificOffspringRemoved,
        pageElementToRemove,
        id,
        pageElementToRemove.parentId,
        pageElementToRemove.order,
        true,
        true,
    );
};

export const onPageContainerDrop = (
    pageElements: ComposerElement[],
    id: string,
    parentId: string | null,
    order: number,
    containerType: ContainerType,
    partialElement: Partial<ComposerElement>,
) => {
    // If there is no container ID then we are adding a new container
    const existingElement = pageElements.find((container) => container.id === id);
    const pageElementDropped = existingElement ?? {
        ...partialElement,
        label: null,
        order,
        parentId,
        attributes: null,
        containerType,
        developerName:
            containerType !== CONTAINER_TYPE['chart']
                ? 'New Container'
                : partialElement.developerName || null,
        id,
        tags: null,
    };

    const orderedPageElement = reOrderPageElements(
        pageElements,
        pageElementDropped,
        id,
        parentId,
        order,
        true,
    );

    return orderedPageElement;
};

export const onPageComponentDrop = (
    pageElements: ComposerElement[],
    id: string,
    parentId: string | null,
    order: number,
    componentType: string,
    partialElement: Partial<ComposerElement>,
) => {
    // If there is no component ID then we are adding a new component
    const existingElement = pageElements.find((container) => container.id === id);
    const pageElementDropped =
        existingElement ??
        createNewComponent({
            ...partialElement,
            id,
            parentId,
            componentType,
            order,
            pageContainerId: parentId,
        });

    const orderedPageElements = reOrderPageElements(
        pageElements,
        pageElementDropped,
        id,
        parentId,
        order,
        true,
    );

    return orderedPageElements;
};
