import { type ElementRef, useEffect, useRef, useState } from 'react';
import translations from '../../../translations';
import type { ChartLine, ChartDataPoint } from '../../../types/dashboard';
import { Chart, type ChartConfiguration } from 'chart.js/auto';
import {
    insightDateFilterItems,
    formatChartDate,
    getDashboardLineChartOptions,
} from '../../../utils/dashboard';
import { View } from '../InsightsDashboard';
import InsightsDateFilter from '../InsightsDateFilter';
import classNames from 'classnames';
import { useInsights } from '../InsightsProvider';

interface Props {
    setCurrentView: (view: View) => void;
}

const ErrorsSummary = ({ setCurrentView }: Props) => {
    const [isHovering, setIsHovering] = useState(false);

    const canvasRef = useRef<ElementRef<'canvas'>>(null);

    const {
        errorsDateFilter,
        setErrorsDateFilter,
        stateErrorsLineChartData,
        setCustomErrorsToDate,
        setErrorsFlowNameFilter,
        setErrorsSearchFilter,
    } = useInsights();

    // The summary page doesn't support any filtering so set all filters to the default and force a re-render with new data from the engine.
    useEffect(() => {
        setCustomErrorsToDate(new Date());
        setErrorsFlowNameFilter('');
        setErrorsSearchFilter('');
    }, [setCustomErrorsToDate, setErrorsFlowNameFilter, setErrorsSearchFilter]);

    useEffect(() => {
        let chart: Chart<
            'line',
            {
                x: string;
                y: number;
            }[],
            unknown
        > | null = null;

        const chartLine: ChartLine[] = [];
        const errorsLineData: ChartDataPoint[] = [];

        // Most of the work is done on the backend but we leave property mapping and date formatting to the client.
        stateErrorsLineChartData.stateErrorsData.forEach((data) => {
            errorsLineData.push({
                x: formatChartDate(new Date(data.bucket), stateErrorsLineChartData.dateFilter),
                y: data.count,
            });
        });

        chartLine.push({
            data: errorsLineData,
            label: 'Errors',
            tension: 0.3,
            backgroundColor: '#C73D58',
            borderColor: '#C73D58',
        });

        const context = canvasRef.current?.getContext('2d');

        if (!context) {
            return;
        }

        const chartConfig: ChartConfiguration<'line', { x: string; y: number }[]> = {
            type: 'line',
            data: { datasets: chartLine },
            options: getDashboardLineChartOptions(),
        };

        chart = new Chart(context, chartConfig);

        // Cleanup the chart to prevent it from wildly animating when switching away and then back to Dashboard tab.
        return () => {
            chart?.destroy();
        };
    }, [stateErrorsLineChartData]);

    const tileClasses = classNames(
        'insights-chart-wrapper',
        'insights-tile',
        isHovering ? 'insights-tile-hover' : '',
    );

    return (
        <div
            className={tileClasses}
            onClick={() => setCurrentView(View.error)}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    setCurrentView(View.error);
                }
            }}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            // biome-ignore lint/a11y/useSemanticElements: Requires refactor
            role="button"
            tabIndex={0}
            data-testid="errors-dashboard-tile"
        >
            <span className="title-bar insight-tile-header">
                <h2>{translations.DASHBOARD_errors_header}</h2>
                <InsightsDateFilter
                    selectedDate={errorsDateFilter}
                    setSelectedDate={setErrorsDateFilter}
                    dateFilterItems={insightDateFilterItems}
                    setParentHovering={setIsHovering}
                />
            </span>
            <div className="insights-chart">
                <canvas ref={canvasRef} data-testid="errors-chart-canvas" />
            </div>
        </div>
    );
};

export default ErrorsSummary;
