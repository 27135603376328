import type { ReactNode } from 'react';

interface Props {
    title?: string | null;
    contentLayout?: string;
    dataTestId?: string;
    children?: ReactNode;
}

const ConfigSection = ({
    title = null,
    contentLayout = 'flex-column',
    dataTestId = '',
    children,
}: Props) => {
    return (
        <section className="sidebar-section" data-testid={dataTestId}>
            {title && <h4 className="sidebar-section-heading">{title}</h4>}
            <div className={`sidebar-section-content ${contentLayout}`}>{children}</div>
        </section>
    );
};

export default ConfigSection;
