import { ArrowRight, Trash } from '@phosphor-icons/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonIcon from '../../../../ts/components/buttons/ButtonIcon';
import ConfirmModal from '../../../../ts/components/generic/modal/ConfirmModal';
import { TAB_TYPES } from '../../../../ts/constants';
import translations from '../../../../ts/translations';
import { isNullOrEmpty } from '../../../../ts/utils/guard';
import { generateRouteUrl } from '../../../../ts/utils/routing';
import { stringReplace } from '../../../../ts/utils/string';
import { useEnvironments } from '../../../../ts/components/admin/environments/EnvironmentsProvider';

const EnvironmentTile = ({ environment, modalContainerRef }) => {
    const navigate = useNavigate();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [environmentId, setEnvironmentId] = useState(null);
    const {
        deleteEnvironment,
        getAllEnvironments,
        managingEnvironments,
        setIsManagingEnvironments,
    } = useEnvironments();

    const editEnvironment = (environment) => {
        if (managingEnvironments) {
            return;
        }

        const route = generateRouteUrl({
            tabType: TAB_TYPES.environment,
            tenantId: environment.tenantId,
            options: {
                tabKey: environment.id,
            },
        });
        navigate(route);
    };

    const removeEnvironment = async () => {
        await deleteEnvironment(environmentId);
        await getAllEnvironments();
        setShowDeleteModal(false);
        setIsManagingEnvironments(false);
    };

    return (
        <>
            <ConfirmModal
                show={showDeleteModal}
                title={translations.ENVIRONMENT_delete}
                messages={[
                    stringReplace(translations.ENVIRONMENT_delete_message, {
                        variableName: environment.name ?? '',
                    }),
                ]}
                buttonStyle="danger"
                buttonCaption="Delete"
                onCancel={() => setShowDeleteModal(false)}
                onConfirm={() => {
                    removeEnvironment();
                }}
                container={modalContainerRef.current}
            />

            <div
                className="wide-tile"
                onClick={() => editEnvironment(environment)}
                onKeyDown={() => editEnvironment(environment)}
                // biome-ignore lint/a11y/useSemanticElements: Requires refactor
                role="button"
                tabIndex="0"
            >
                <div className="wide-flow-tile">
                    <header className="wide-tile-header">
                        <h5>
                            {environment.name}

                            <ButtonIcon
                                onClick={() => {
                                    setEnvironmentId(environment.id);
                                    setShowDeleteModal(true);
                                }}
                                className="float-right"
                                title="Delete Environment"
                                hidden={
                                    !managingEnvironments ||
                                    environment.classificationType !== 'Test'
                                }
                                testId={`btnDelete${environment.name}`}
                            >
                                <Trash />
                            </ButtonIcon>
                        </h5>
                    </header>

                    <main className="wide-tile-content">
                        <div className="margin-top-small margin-bottom-small">
                            <span>
                                <b>Description: </b>
                                {environment.description ? environment.description : 'N/A'}
                            </span>
                        </div>
                        <div className="margin-bottom-small">
                            <span>
                                <b>Last deployed: </b>
                                {isNullOrEmpty(environment.lastDeployed)
                                    ? 'Never'
                                    : new Date(environment.lastDeployed).toLocaleString()}
                            </span>
                        </div>
                        <div className="margin-bottom-small">
                            <span data-testid={`${environment.name}Default`}>
                                <b>Default environment: </b>
                                {environment.isDefault ? 'Yes' : 'No'}
                            </span>
                        </div>
                    </main>

                    <footer className="wide-tile-footer">
                        <button
                            onClick={() => editEnvironment(environment)}
                            className="link-emulate"
                            type="button"
                        >
                            Manage {environment.name}{' '}
                            <ArrowRight className="vertical-align-top" weight="bold" size={15} />
                        </button>
                    </footer>
                </div>
            </div>
        </>
    );
};

export default EnvironmentTile;
