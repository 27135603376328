import translations from '../../../../../../translations';
import type { RuleSummaryData } from '../../../../../../types';
const RuleSummary = ({ flags, left, criteriaPhrase, right }: RuleSummaryData) => {
    const { isComponentChange, isPageLoad, isIncompatibleCriteria, isIncompatibleContentTypes } =
        flags;
    const {
        componentName: leftComponentName,
        valueName: leftValueName,
        property: leftProperty,
    } = left;
    const { category: rightCategory, name: rightName, property: rightProperty } = right;

    const criteriaPhraseContainsEmpty = criteriaPhrase?.includes('EMPTY');

    // WHEN component [comp_name] changes
    // WHEN page loads
    const triggerSummary = isComponentChange ? (
        <>
            <span className="summary-keyword">WHEN</span> component{' '}
            <span className="summary-name">&quot;{leftComponentName || 'UNKNOWN'}&quot;</span>{' '}
            changes
        </>
    ) : isPageLoad ? (
        <>
            <span className="summary-keyword">WHEN</span> page loads
        </>
    ) : (
        <>
            <span className="summary-keyword">WHEN</span> UNKNOWN CONDITION
        </>
    );

    // IF component [comp_name] property_name
    // IF value [value_name]
    const leftSummary =
        leftComponentName && !leftValueName ? (
            <>
                <span className="summary-keyword">IF</span> component{' '}
                <span className="summary-name">&quot;{leftComponentName || 'UNKNOWN'}&quot;</span>{' '}
                {leftProperty || ''}
            </>
        ) : leftValueName ? (
            <>
                <span className="summary-keyword">IF</span> value{' '}
                <span className="summary-name">&quot;{leftValueName || 'UNKNOWN'}&quot;</span>
            </>
        ) : (
            <>
                <span className="summary-keyword">IF</span> UNKNOWN CONDITION
            </>
        );

    // IS ... TO/THAN/WITH
    const criteriaSummary = <span className="summary-keyword">{criteriaPhrase}</span>;

    // component [comp_name] property_name
    // value (value_name)
    const rightSummary = criteriaPhraseContainsEmpty ? (
        <>
            {/* Example: IF value "ValueName" IS EMPTY equals to "SomeBool" */}
            {' equals to '}
            <span className="summary-name">&quot;{rightName || 'UNKNOWN'}&quot;</span>{' '}
            {rightProperty || ''}
        </>
    ) : (
        <>
            {rightCategory?.toLowerCase()}{' '}
            <span className="summary-name">&quot;{rightName || 'UNKNOWN'}&quot;</span>{' '}
            {rightProperty || ''}
        </>
    );

    return (
        <div className="formatted-rule-summary" data-testid="formatted-rule-summary">
            {triggerSummary}
            <br />
            {leftSummary} {criteriaSummary} {rightSummary}
            {isIncompatibleCriteria && (
                <span className="help-block error-state" data-testid="rule-criteria-error">
                    &#10551; RULE ERROR:{' '}
                    {translations.PAGE_BUILDER_page_conditions_invalid_criteria}
                </span>
            )}
            {isIncompatibleContentTypes && (
                <span className="help-block error-state" data-testid="rule-type-error">
                    &#10551; RULE ERROR:{' '}
                    {translations.PAGE_BUILDER_page_conditions_invalid_content_types}
                </span>
            )}
        </div>
    );
};

export default RuleSummary;
