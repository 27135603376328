import translations from '../../../../../../translations';
import type { OperationSummaryData } from '../../../../../../types';

const OperationSummary = ({ left, right, isIncompatibleContentTypes }: OperationSummaryData) => {
    const { category: leftCategory, name: leftName, property: leftProperty } = left;
    const { category: rightCategory, name: rightName, property: rightProperty } = right;

    const leftSummary = (
        <>
            <span className="summary-keyword">SET</span> {leftCategory?.toLowerCase()}{' '}
            <span className="summary-name">&quot;{leftName || 'UNKNOWN'}&quot;</span>{' '}
            {leftProperty || ''}
        </>
    );

    const rightSummary = (
        <>
            <span className="summary-keyword">TO</span> {rightCategory?.toLowerCase()}{' '}
            <span className="summary-name">&quot;{rightName || 'UNKNOWN'}&quot;</span>{' '}
            {rightProperty || ''}
        </>
    );

    return (
        <div className="formatted-operation-summary" data-testid="formatted-operation-summary">
            {leftSummary} {rightSummary}
            {isIncompatibleContentTypes && (
                <span className="help-block error-state" data-testid="operation-type-error">
                    &#10551; OPERATION ERROR:{' '}
                    {translations.PAGE_BUILDER_page_conditions_invalid_content_types}
                </span>
            )}
        </div>
    );
};

export default OperationSummary;
