import { type ChangeEventHandler, useState } from 'react';
import '../../../../css/text-editor.less';
import translations from '../../translations';

type TextEditorProps = {
    text: string;
    isEditing: boolean;
    onChange: ChangeEventHandler<HTMLInputElement>;
    labelledBy: string;
};

const TextEditor = ({
    onChange,
    text = '',
    isEditing: isEditingInitial = false,
    labelledBy,
}: TextEditorProps) => {
    const [isEditing, setIsEditing] = useState(isEditingInitial);

    return (
        <div
            className="text-editor flex"
            onKeyDown={() => setIsEditing(true)}
            onFocus={() => setIsEditing(true)}
            onClick={() => setIsEditing(true)}
            // biome-ignore lint/a11y/useSemanticElements: Requires refactor
            role="button"
            tabIndex={0}
            data-testid="text-editor"
        >
            {isEditing ? (
                <input
                    className="flex-grow text-padding text-editor-input"
                    autoFocus={true}
                    value={text}
                    onChange={onChange}
                    onBlur={() => setIsEditing(false)}
                    data-testid="text-editor-input"
                    aria-labelledby={labelledBy}
                />
            ) : (
                <span className="flex-grow text-padding text-editor-display">{text}</span>
            )}
            {isEditing ? null : (
                <button
                    className="btn-icon flex-child-right"
                    data-testid="text-editor-button"
                    onClick={() => setIsEditing(true)}
                    type="button"
                    aria-label={translations.COMMON_edit}
                >
                    <span className="glyphicon glyphicon-pencil" />
                </button>
            )}
        </div>
    );
};

export default TextEditor;
