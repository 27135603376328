import classnames from 'classnames';
import Icon from '../graphv2/render/Icon';
import { memo } from 'react';
import type { GroupNode as GroupNodeType } from '../../types';
import { XCircle } from '@phosphor-icons/react';

interface Props {
    node: GroupNodeType;
    patternId: string;
}

const resizeCornerSize = 20;
const resizeThickness = 15;
const halfThickness = resizeThickness / 2;

const GroupNode = ({ node, patternId }: Props) => {
    const {
        id,
        x,
        y,
        height,
        width,
        iconName,
        label,
        description,
        isSelected,
        canDelete,
        color,
        borderRadius,
    } = node;

    /*
         XL     XR
        ┏━┵─────┶━┓
    YT  ┦         ┦
        │         │
    YB  ┧         ┧
        ┗━┵─────┶━┛
    */
    const xLeft = Math.min(resizeCornerSize, width / 2);
    const xRight = Math.max(width - resizeCornerSize, width / 2);
    const yTop = Math.min(resizeCornerSize, height / 2);
    const yBottom = Math.max(height - resizeCornerSize, height / 2);

    const resizeHandles = [
        /* ━━ Top */
        {
            side: 'top',
            cursor: 'n-resize',
            edges: [
                { x: xLeft, y: -halfThickness, width: xRight - xLeft, height: resizeThickness },
            ],
        },
        /* ━┓ Top Right */
        {
            side: 'top,right',
            cursor: 'ne-resize',
            edges: [
                {
                    x: xRight,
                    y: -halfThickness,
                    width: xLeft + halfThickness,
                    height: resizeThickness,
                },
                {
                    x: width - halfThickness,
                    y: halfThickness,
                    width: resizeThickness,
                    height: yTop - halfThickness,
                },
            ],
        },
        /* ┃  Right */
        {
            side: 'right',
            cursor: 'e-resize',
            edges: [
                {
                    x: width - halfThickness,
                    y: yTop,
                    width: resizeThickness,
                    height: yBottom - yTop,
                },
            ],
        },
        /* ━┛ Bottom Right */
        {
            side: 'bottom,right',
            cursor: 'se-resize',
            edges: [
                {
                    x: xRight,
                    y: height - halfThickness,
                    width: xLeft + halfThickness,
                    height: resizeThickness,
                },
                {
                    x: width - halfThickness,
                    y: yBottom,
                    width: resizeThickness,
                    height: yTop - halfThickness,
                },
            ],
        },
        /* ━━ Bottom */
        {
            side: 'bottom',
            cursor: 's-resize',
            edges: [
                {
                    x: xLeft,
                    y: height - halfThickness,
                    width: xRight - xLeft,
                    height: resizeThickness,
                },
            ],
        },
        /* ┗━ Bottom Left */
        {
            side: 'bottom,left',
            cursor: 'sw-resize',
            edges: [
                {
                    x: -halfThickness,
                    y: height - halfThickness,
                    width: xLeft + halfThickness,
                    height: resizeThickness,
                },
                {
                    x: -halfThickness,
                    y: yBottom,
                    width: resizeThickness,
                    height: yTop - halfThickness,
                },
            ],
        },
        /* ┃  Left */
        {
            side: 'left',
            cursor: 'w-resize',
            edges: [{ x: -halfThickness, y: yTop, width: resizeThickness, height: yBottom - yTop }],
        },
        /* ┏━ Top Left */
        {
            side: 'top,left',
            cursor: 'nw-resize',
            edges: [
                {
                    x: -halfThickness,
                    y: -halfThickness,
                    width: xLeft + halfThickness,
                    height: resizeThickness,
                },
                {
                    x: -halfThickness,
                    y: halfThickness,
                    width: resizeThickness,
                    height: yTop - halfThickness,
                },
            ],
        },
    ];

    const selectionMarqueeDistance = 5;
    const selectionMarqueeHeight = height + selectionMarqueeDistance * 2;
    const selectionMarqueeWidth = width + selectionMarqueeDistance * 2;
    const selectionMarqueeRadius = borderRadius + selectionMarqueeDistance;

    return (
        <g
            id={id}
            className={'group'}
            transform={`matrix(1,0,0,1,${x},${y})`}
            height={height}
            width={width}
            aria-label={description}
            data-object="groupNode"
            data-id={node.id}
        >
            {/* Grey selection border to help indicate selection/focus */}
            {isSelected && (
                <rect
                    width={selectionMarqueeWidth}
                    height={selectionMarqueeHeight}
                    x={-selectionMarqueeDistance}
                    y={-selectionMarqueeDistance}
                    stroke="#9c9c9c"
                    rx={selectionMarqueeRadius}
                    strokeWidth="0.7"
                    strokeDasharray="2"
                    fill="none"
                    data-testid="map-element-selected"
                />
            )}
            {/* Border around the whole group */}
            <rect
                className="group-element-border"
                stroke={color}
                strokeOpacity={1}
                rx={borderRadius}
                strokeWidth={2.1}
                pointerEvents="none"
                fill={`url(#${patternId})`}
                fillOpacity={0.5}
                height={height}
                width={width}
                data-testid={`border-${id}`}
            />
            <g data-testid="groupElement">
                {/* Square bottom to group header */}
                <rect
                    fill={color}
                    y={20}
                    height={10}
                    width={width}
                    className="group-element-drag-handle"
                />
                {/* Rounded header */}
                <rect
                    rx={4}
                    fill={color}
                    height={30}
                    width={width}
                    className="group-element-drag-handle"
                />
            </g>
            {/* Green dragging square around the contents to highlight when a map element is being dragging into this group */}
            {/* {draggingOver && (
                <rect
                    strokeWidth="2"
                    stroke="#0f0"
                    fill="none"
                    x={-2}
                    y={-2 + styles.header.height}
                    height={height - styles.header.height + 4}
                    width={width + 4}
                />
            )} */}
            {/* Group name, icon and content */}
            <foreignObject
                x={5}
                y={5}
                width={width - 10}
                height={20}
                className="group-element-drag-handle"
            >
                <span className="map-element-content">
                    <span
                        className={classnames({
                            'map-element-icon': true,
                            'safari-fix': true,
                            glyphicon: true,
                        })}
                    >
                        <Icon name={iconName} size={15} />
                    </span>
                    <span className={'group-element-text'}>
                        <span
                            suppressContentEditableWarning={true}
                            data-testid="editable-name"
                            // biome-ignore lint/a11y/useSemanticElements: Requires refactor
                            role="textbox"
                            tabIndex={-1}
                            // If they mouse down on this after selection
                            // onMouseDown={() => {
                            //     if (isSelected) {
                            //         setEditMouseDown(true);
                            //     }
                            // }}
                            // // and then mouse up on this after that, then we can be sure they want to edit the name
                            // onMouseUp={(e) => {
                            //     if (editMouseDown) {
                            //         setEditingName(true);
                            //         setEditMouseDown(false);

                            //         // If they've only just started editing
                            //         if (editingName === false) {
                            //             // then also select all the text in the group so they can rename easier
                            //             window.getSelection()?.selectAllChildren(e.target as Node);
                            //         }
                            //     }
                            //     selectGroupElement(e);
                            //     // Don't block dragging.hasMovedEnough events,
                            //     // as those need to propagate to OnDragEnd to end the drag
                            //     if (!dragging) {
                            //         // Stop this mouseUp event from propagating up if the user isn't dragging,
                            //         // because if the graph gets this event, it'll deselect everything
                            //         e.stopPropagation();
                            //     }
                            // }}
                            // onBlur={saveName}
                            // // Stop propagation so DELETE key doesn't delete group
                            // onKeyUp={(e) => e.stopPropagation()}
                            // // Stop propagation so clicking to edit doesn't edit group
                            // onDoubleClick={(e) => e.stopPropagation()}
                            // // If they move the mouse during editing, then stop propagation of that event so the element doesn't move
                            // // If they move the mouse after mouse down, then unset mouse down, as they probably want to drag and not edit
                            // onMouseMove={(e) => {
                            //     if (editingName) {
                            //         e.stopPropagation();
                            //     }
                            //     // Only cancel the mouse down if they moved significantly
                            //     if (dragging?.hasMovedEnough) {
                            //         setEditMouseDown(false);
                            //     }
                            // }}
                            className={classnames({
                                'map-element-developer-name': true,
                                'map-element-no-actions': true,
                            })}
                            title={label}
                            contentEditable={false}
                        >
                            {label}
                        </span>
                    </span>
                </span>
            </foreignObject>
            {/* Resize handles for scaling the group */}
            {resizeHandles.map(({ edges, cursor }, handleIndex) => (
                <g
                    style={{ cursor }}
                    className="resize-handle"
                    key={`${id}-${
                        // biome-ignore lint/suspicious/noArrayIndexKey: Unsure what to use instead of index
                        handleIndex
                    }`}
                >
                    {edges.map((edge, edgeIndex) => (
                        <rect
                            fill="transparent"
                            x={edge.x}
                            y={edge.y}
                            width={edge.width}
                            height={edge.height}
                            // biome-ignore lint/suspicious/noArrayIndexKey: Unsure what to use instead of index
                            key={`${id}-handle-${handleIndex}-edge-${edgeIndex}`}
                        />
                    ))}
                </g>
            ))}

            {/* Delete button */}
            {canDelete && (
                <foreignObject x={width} y={-10} width={10} height={10}>
                    <XCircle
                        size={10}
                        weight="fill"
                        className="group-element-icon group-element-delete"
                        data-action="delete"
                        data-testid="elementDeleteButton"
                        alt="Delete"
                    />
                </foreignObject>
            )}
            {/*
                    {elementType === MAP_ELEMENT_TYPES.group && (
                        <>
                            <UngroupIcon
                                onClick={onUngroup}
                                onMouseUp={(e: MouseEvent) => executeIfNotDragging(e, dragging)}
                                className="group-element-icon group-element-unlink"
                                title="Ungroup"
                            />
                        </>
                    )}
                    <AutoArrangeIcon
                        onClick={onAutoArrangeGroup}
                        onMouseUp={(e: MouseEvent) => executeIfNotDragging(e, dragging)}
                        className="group-element-icon group-element-arrange"
                        alt="Auto Arrange"
                    />
            */}
        </g>
    );
};

export default memo(GroupNode);
