import { useEffect, useState } from 'react';
import Loader from '../../../../../../../loader/Loader';
import { NOTIFICATION_TYPES } from '../../../../../../../../constants';
import { getType } from '../../../../../../../../sources/type';
import { getValue, getValueList } from '../../../../../../../../sources/value';
import { COMPONENT_CONFIGURATION_LABELS, DATA_SOURCE } from '../../../../../../constants';
import { usePageEditor } from '../../../../../PageEditorProvider';
import ConfigSection from '../../ConfigSection';
import DataPresentationList from './data-presentation-list/DataPresentationList';
import DataPresentationEditor from './data-presentation-editor/DataPresentationEditor';
import ScreenRouter from '../../../../../../../generic/ScreenRouter';
import type {
    ColumnOption,
    ObjectDataRequest,
    ValueElementIdAPI,
    column,
} from '../../../../../../../../types';
import {
    determineSourceType,
    isConnectorSourceConfigured,
    isListSourceConfigured,
} from '../../../../../../utils';

const DETAIL_VIEW = 'DETAIL_VIEW';
const LIST_VIEW = 'LIST_VIEW';

// When switching between data sources on different page components the data presentation will reset by default,
// but when switching between data sources on the same page component we need to check any existing
// data presentation against the properties from the new data source. If there are no matches then
// the existing data presentation should be reset as it no longer matches the latest property options.
const checkDataPresentationProperties = (dpCfg: column[], poCfg: ColumnOption[]) => {
    const poIDs = poCfg.map((po) => po.value);
    const dpIDs = dpCfg.map((dp) => dp.typeElementPropertyId);

    // Do the latest property options include all the data presentation properties?
    return dpIDs.every((id) => poIDs.includes(id || ''));
};

interface Props {
    configuredDataSource: ObjectDataRequest | ValueElementIdAPI | null;
    dataPresentation: column[] | null;
    updateDataPresentation: (columns: column[] | null | undefined) => void;
    stateSaveValue: ValueElementIdAPI | null | undefined;
    defaultSourceTypeElementId: string | null;
    hasEditableColumns: boolean;
    canUseCustomComponent: boolean;
    canPinColumns: boolean;
}

const DataPresentationConfig = ({
    configuredDataSource,
    dataPresentation,
    updateDataPresentation,
    stateSaveValue,

    // This is defined in the registered page component and if specified
    // will allow the properties dropdown to be populated when no
    // data source has been defined.
    defaultSourceTypeElementId,
    hasEditableColumns,
    canUseCustomComponent,
    canPinColumns,
}: Props) => {
    const { addNotification } = usePageEditor();

    const [loadingPropertyOptions, setLoadingPropertyOptions] = useState(false);
    const [propertyOptions, setPropertyOptions] = useState<ColumnOption[] | null>(null);
    const [editedItemIndex, setEditedItemIndex] = useState<number | null>(null);
    const [dataSourceTypeElementId, setDataSourceTypeElementId] = useState<string | null>(null);
    const [screen, setScreen] = useState(LIST_VIEW);

    const listSource = configuredDataSource as ValueElementIdAPI;
    const connectorSource = configuredDataSource as ObjectDataRequest;

    const source = determineSourceType(configuredDataSource);

    const isConfigured =
        source === DATA_SOURCE['LIST']
            ? isListSourceConfigured(listSource)
            : isConnectorSourceConfigured(connectorSource);

    const dataSourceIdentifier =
        source === DATA_SOURCE['LIST']
            ? listSource?.id
            : connectorSource?.typeElementId || defaultSourceTypeElementId;

    const createItem = () => {
        setScreen(DETAIL_VIEW);
    };

    const getPropertiesByTypeElementId = async () => {
        const rawValue = await getValue(listSource.id || '');
        const rawType = await getType(rawValue.typeElementId || '');

        return {
            typeElementId: rawType.id,
            properties: rawType.properties,
        };
    };

    const getPropertiesByTypeElementPropertyId = async () => {
        const allValueReferences = await getValueList({ id: listSource.id });
        const selectedValueReference = allValueReferences.find(
            (vr) =>
                vr.id === listSource.id &&
                vr.typeElementPropertyId === listSource.typeElementPropertyId,
        );

        if (
            !selectedValueReference ||
            selectedValueReference.typeElementPropertyTypeElementId === null
        ) {
            throw Error('Could not find the specified value');
        }

        const typeId = selectedValueReference.typeElementPropertyTypeElementId;
        const rawType = await getType(typeId);

        return {
            typeElementId: rawType.id,
            properties: rawType.properties,
        };
    };

    const fetchPropertiesFromDataSource = async () => {
        try {
            // Get the type from the data source config, then the properties on that type.
            if ((source && isConfigured) || defaultSourceTypeElementId) {
                setLoadingPropertyOptions(true);

                if (source === DATA_SOURCE['LIST']) {
                    const { typeElementId, properties } =
                        listSource.typeElementPropertyId !== null
                            ? await getPropertiesByTypeElementPropertyId()
                            : await getPropertiesByTypeElementId();

                    const propertyOptions = properties.map((prop) => ({
                        value: prop.id,
                        label: prop.developerName,
                        type: prop.contentType,
                    }));

                    setPropertyOptions(propertyOptions);
                    setDataSourceTypeElementId(typeElementId);
                }

                if (source === DATA_SOURCE['CONNECTOR'] || defaultSourceTypeElementId) {
                    const rawType = await getType(dataSourceIdentifier as string);
                    const propertyOptions = rawType.properties.map((prop) => ({
                        value: prop.id,
                        label: prop.developerName,
                        type: prop.contentType,
                    }));

                    setPropertyOptions(propertyOptions);
                    setDataSourceTypeElementId(rawType.id);
                }
            }
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: `Error fetching properties: ${(error as Error).message}`,
                isPersistent: true,
            });
        } finally {
            setLoadingPropertyOptions(false);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        if (dataSourceIdentifier) {
            fetchPropertiesFromDataSource();
        } else {
            // Clear any potentially leftover property options and data source info.
            propertyOptions && setPropertyOptions(null);
            dataSourceTypeElementId && setDataSourceTypeElementId(null);
        }

        // The data source does not exist anymore (ex.: a list value data source was removed),
        // so we should clear any data presentation config that was based on that data source.
        if (!dataSourceIdentifier && dataPresentation?.length) {
            updateDataPresentation(null);
        }

        return () => editorCancel();
    }, [dataSourceIdentifier, connectorSource?.typeElementBindingId]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        if (dataPresentation?.length && propertyOptions?.length) {
            // If we select a different/new data source with a new set of properties,
            // then we should check and likely clear any old/existing data presentation config
            // that was made using the properties from the previous data source.
            !checkDataPresentationProperties(dataPresentation, propertyOptions) &&
                updateDataPresentation(null);
        }
    }, [propertyOptions]);

    const editItem = (itemIndex: number) => {
        setScreen(DETAIL_VIEW);
        setEditedItemIndex(itemIndex);
    };

    const deleteItem = (itemIndex: number) => {
        const updated = dataPresentation?.filter((_, index) => index !== itemIndex);

        if (updated !== undefined) {
            updateDataPresentation(updated.length > 0 ? updated : null);
        }
    };

    const editorCancel = () => {
        setScreen(LIST_VIEW);
        editedItemIndex !== null && setEditedItemIndex(null);
    };

    return (
        <ConfigSection
            dataTestId="data-presentation-config"
            title={COMPONENT_CONFIGURATION_LABELS['DATA_PRESENTATION']}
        >
            {loadingPropertyOptions ? (
                <Loader />
            ) : (
                <ScreenRouter currentScreen={screen}>
                    <DataPresentationList
                        screen={LIST_VIEW}
                        listItems={dataPresentation}
                        editItem={editItem}
                        deleteItem={deleteItem}
                        updateDataPresentation={updateDataPresentation}
                        createItem={createItem}
                    />
                    <DataPresentationEditor
                        screen={DETAIL_VIEW}
                        stateSaveValue={stateSaveValue}
                        dataSourceTypeElementId={dataSourceTypeElementId}
                        listItems={dataPresentation}
                        selectedItemIndex={editedItemIndex}
                        propertyOptions={propertyOptions}
                        cancel={editorCancel}
                        save={updateDataPresentation}
                        hasEditableColumns={hasEditableColumns}
                        canUseCustomComponent={canUseCustomComponent}
                        canPinColumns={canPinColumns}
                    />
                </ScreenRouter>
            )}
        </ConfigSection>
    );
};

export default DataPresentationConfig;
