import { useEffect, useState } from 'react';
import { getNavigationElements } from '../../../../../../ts/sources/flow';
import { isNullOrEmpty } from '../../../../../../ts/utils/guard';
import { getByID } from '../../../../../../ts/utils/collection';
import ButtonDefault from '../../../../../../ts/components/buttons/ButtonDefault';
import ButtonPrimary from '../../../../../../ts/components/buttons/ButtonPrimary';
import FormGroup from '../../../../../../ts/components/generic/FormGroup';
import Toggle from '../../../../../../ts/components/inputs/Toggle';
import { useMapElement } from '../../contextProviders/MapElementProvider';
import NavigationUrlInputs from '../../../../../../ts/components/flow/NavigationUrlInputs';
import { URL_TARGET } from '../../../../../../ts/components/flow/constants';
import Select from 'react-select';
import ModalBody from '../../../../../../ts/components/generic/modal/ModalBody';
import ModalFooter from '../../../../../../ts/components/generic/modal/ModalFooter';
import { getSharedStyles } from '../../../../../../ts/utils/select';

const NavigationOverrideDetails = () => {
    const {
        flowId,
        editingToken,
        mapElement,
        onSwitchScreen,
        selectedNavigationIndex,
        setSelectedNavigationIndex,
        tenantId,
        notifyError,
        mapElements,
        defaultScreen,
        setMapElement,
        setConfigTitle,
    } = useMapElement();

    // State values
    const [navigations, setNavigations] = useState([]);
    const [selectedNavigationId, setSelectedNavigationId] = useState(
        mapElement.navigationOverrides?.[selectedNavigationIndex]?.navigationElementId ?? null,
    );
    const [selectedItemId, setSelectedItemId] = useState(
        mapElement.navigationOverrides?.[selectedNavigationIndex]?.navigationItemId ?? null,
    );
    const [navigationItems, setNavigationItems] = useState([]);
    const [selectedMapElementId, setSelectedMapElementId] = useState(
        mapElement.navigationOverrides?.[selectedNavigationIndex]?.locationMapElementId ?? null,
    );
    const [selectedMapElementName, setSelectedMapElementName] = useState(
        mapElement.navigationOverrides?.[selectedNavigationIndex]
            ?.locationMapElementDeveloperName ?? null,
    );
    const [isEnabled, setIsEnabled] = useState(
        mapElement.navigationOverrides?.[selectedNavigationIndex]?.isEnabled ?? true,
    );
    const [isVisible, setIsVisible] = useState(
        mapElement.navigationOverrides?.[selectedNavigationIndex]?.isVisible ?? true,
    );
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [isUrl, setIsUrl] = useState(
        !isNullOrEmpty(mapElement.navigationOverrides?.[selectedNavigationIndex]?.url),
    );
    const [url, setUrl] = useState(
        mapElement.navigationOverrides?.[selectedNavigationIndex]?.url ?? null,
    );
    const [urlTarget, setUrlTarget] = useState(
        mapElement.navigationOverrides?.[selectedNavigationIndex]?.urlTarget ?? URL_TARGET.BLANK,
    );
    const [hasUrlError, setHasUrlError] = useState(false);

    const isNavigationValid = selectedNavigationId !== null;
    const isItemValid = selectedItemId !== null;
    const isUrlValid = !isUrl || (isUrl && url !== null);

    const isFormValid = isNavigationValid && isItemValid && isUrlValid;

    const onLocationChange = (option) => {
        const label = option?.label;
        const value = option?.value;

        if (value === 'URL') {
            setIsUrl(true);
            return;
        }

        setIsUrl(false);
        setUrl(null);
        setUrlTarget(null);
        setSelectedMapElementName(label);
        setSelectedMapElementId(value);
    };

    const onSave = () => {
        setHasSubmitted(true);
        if (isFormValid) {
            const selectedNavigationElement = getByID(selectedNavigationId, navigations);
            const selectedMapElement = getByID(selectedMapElementId, mapElements);
            const selectedItem = getByID(selectedItemId, navigationItems);

            const navigationOverride = {
                isEnabled,
                isVisible,
                locationMapElementDeveloperName: selectedMapElement?.developerName ?? null,
                locationMapElementId: selectedMapElementId,
                navigationElementDeveloperName: selectedNavigationElement.developerName,
                navigationElementId: selectedNavigationId,
                navigationItemDeveloperName: selectedItem.developerName,
                navigationItemId: selectedItemId,
            };

            if (isUrl) {
                navigationOverride.url = url;
                navigationOverride.urlTarget = urlTarget;
            }

            const index = selectedNavigationIndex ?? mapElement.navigationOverrides?.length ?? 0;
            const navigationOverrides = [...(mapElement.navigationOverrides || [])];
            navigationOverrides[index] = navigationOverride;

            setMapElement({
                ...mapElement,
                navigationOverrides,
            });

            onSwitchScreen(defaultScreen);
        } else if (!isUrlValid) {
            setHasUrlError(true);
        }
    };

    const onCancel = () => {
        onSwitchScreen(defaultScreen);
        setSelectedNavigationIndex(null);
    };

    const fetchNavigations = async () => {
        try {
            const response = await getNavigationElements({
                tenantId,
                flowId,
                editingToken,
            });

            setNavigations(response);
        } catch (error) {
            notifyError(error);
        }
    };

    const fetchNavigationItems = async (navigationElementId) => {
        try {
            const response = await getNavigationElements({
                tenantId,
                flowId,
                editingToken,
                navigationElementId,
            });

            setNavigationItems(response.navigationItems || []);
        } catch (error) {
            notifyError(error);
        }
    };

    const onChangeNavigation = ({ target: { value } }) => {
        fetchNavigationItems(value);
        setSelectedNavigationId(value);
    };

    const onUrlChanged = (value) => {
        setUrl(value);
    };

    const updateUrlTarget = (e) => {
        e.preventDefault();

        setUrlTarget(e.currentTarget.value);
    };

    const renderBody = () => (
        <>
            <FormGroup
                label="Which Navigation do you want to override?"
                isRequired
                isValid={isNavigationValid}
                validationMessage="Please select a Navigation."
                showValidation={hasSubmitted}
                htmlFor="navigation-select"
            >
                <select
                    className="form-control form-select form-control-width"
                    value={selectedNavigationId ?? ''}
                    onChange={onChangeNavigation}
                    id="navigation-select"
                    data-testid="navigation-select"
                >
                    {selectedNavigationId === null && (
                        <option value="" key="empty">
                            Select a Navigation
                        </option>
                    )}
                    {navigations.map((navigation) => (
                        <option value={navigation.id} key={navigation.id}>
                            {navigation.developerName}
                        </option>
                    ))}
                </select>
            </FormGroup>

            <FormGroup
                label="Which item do you want to override?"
                isRequired
                isValid={isItemValid}
                validationMessage="Please select a Navigation item."
                showValidation={hasSubmitted}
                htmlFor="navigation-item-select"
            >
                <select
                    className="form-control form-select form-control-width"
                    value={selectedItemId ?? ''}
                    onChange={({ target: { value } }) => setSelectedItemId(value)}
                    disabled={selectedNavigationId === null}
                    id="navigation-item-select"
                    data-testid="navigation-item-select"
                >
                    {selectedItemId === null && selectedNavigationId !== null && (
                        <option value="" key="empty">
                            Select an item
                        </option>
                    )}
                    {navigationItems.map((navigation) => (
                        <option value={navigation.id} key={navigation.id}>
                            {navigation.developerName ?? navigation.label}
                        </option>
                    ))}
                </select>
            </FormGroup>

            <FormGroup
                label="Change the location of the item to:"
                htmlFor="navigation-location-select"
            >
                <Select
                    inputId="navigation-location-select"
                    data-testid="navigation-location-select"
                    styles={{
                        ...getSharedStyles(),
                        container: (baseStyles) => ({ ...baseStyles, width: '300px' }),
                    }}
                    options={[{ label: 'URL', value: 'URL' }].concat(
                        mapElements.map((mapElementLocation) => ({
                            label: mapElementLocation.developerName,
                            value: mapElementLocation.id,
                        })),
                    )}
                    onChange={onLocationChange}
                    placeholder="Select a map element or go to a URL"
                    noOptionsMessage={() => 'No results found'}
                    value={
                        isUrl
                            ? { label: 'URL', value: 'URL' }
                            : selectedMapElementId
                              ? { label: selectedMapElementName, value: selectedMapElementId }
                              : null
                    }
                    isClearable
                    menuPosition="fixed"
                    menuPortalTarget={document.querySelector(`#flow-${flowId} .modal`)}
                    closeMenuOnScroll={(e) => {
                        if (e.target === document.querySelector(`#flow-${flowId} .modal-body`)) {
                            return true;
                        }
                        return false;
                    }}
                />
            </FormGroup>

            {isUrl && (
                <NavigationUrlInputs
                    hasUrlError={hasUrlError}
                    url={url}
                    urlTarget={urlTarget}
                    onUrlChanged={onUrlChanged}
                    updateUrlTarget={updateUrlTarget}
                    controlWidth={true}
                />
            )}

            <FormGroup>
                <label htmlFor="is-visible-to-users-toggle">
                    <Toggle
                        id="is-visible-to-users-toggle"
                        isOn={isVisible}
                        onChange={() => setIsVisible(!isVisible)}
                    />
                    Should the item be visible to users?
                </label>
            </FormGroup>

            <FormGroup>
                <label htmlFor="is-enabled-for-users-toggle">
                    <Toggle
                        id="is-enabled-for-users-toggle"
                        isOn={isEnabled}
                        onChange={() => setIsEnabled(!isEnabled)}
                    />
                    Should the item be enabled for users to select?
                </label>
            </FormGroup>
        </>
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        fetchNavigations();

        if (selectedNavigationId !== null) {
            fetchNavigationItems(selectedNavigationId);
        }
    }, []);

    useEffect(() => {
        setConfigTitle(`Navigation Override for: ${mapElement.developerName}`);

        return () => setConfigTitle(null);
    }, [mapElement.developerName, setConfigTitle]);

    return (
        <>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>
                <ButtonDefault className="flex-child-right" onClick={onCancel}>
                    Cancel
                </ButtonDefault>
                <ButtonPrimary className="margin-left" onClick={onSave}>
                    {selectedNavigationIndex === null ? 'Add' : 'Update'}
                </ButtonPrimary>
            </ModalFooter>
        </>
    );
};

export default NavigationOverrideDetails;
