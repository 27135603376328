import DecisionConfiguration from './DecisionConfiguration';
import OutcomeDetails from '../../../../../ts/components/flow/elementConfigurations/common/OutcomeDetails';
import screens from '../common/screens';
import ScreenRouter from '../../../../../ts/components/generic/ScreenRouter';
import { useMapElement } from '../contextProviders/MapElementProvider';

const DecisionRouter = () => {
    const {
        currentScreen,
        mapElement,
        selectedOutcomeIndex,
        setSelectedOutcomeIndex,
        onSwitchScreen,
    } = useMapElement();

    return (
        <ScreenRouter currentScreen={currentScreen}>
            <DecisionConfiguration screen={screens.decision} />
            <OutcomeDetails
                screen={screens.outcome}
                outcome={mapElement?.outcomes?.[selectedOutcomeIndex]}
                onSave={() => onSwitchScreen(screens.decision)}
                onCancel={() => {
                    onSwitchScreen(screens.decision);
                    setSelectedOutcomeIndex(null);
                }}
            />
        </ScreenRouter>
    );
};

export default DecisionRouter;
