import { useMapElement } from '../contextProviders/MapElementProvider';
import StepConfiguration from './StepConfiguration';
import NavigationOverrideDetails from '../common/navigationOverrides/NavigationOverrideDetails';
import OutcomeDetails from '../../../../../ts/components/flow/elementConfigurations/common/OutcomeDetails';
import screens from '../common/screens';
import ScreenRouter from '../../../../../ts/components/generic/ScreenRouter';

/**
 * Handles switching between the step configuration screens
 */
const StepRouter = () => {
    const {
        currentScreen,
        mapElement,
        selectedOutcomeIndex,
        setSelectedOutcomeIndex,
        onSwitchScreen,
    } = useMapElement();

    return (
        <ScreenRouter currentScreen={currentScreen}>
            <StepConfiguration screen={screens.step} />
            <NavigationOverrideDetails screen={screens.navigationOverrides} />
            <OutcomeDetails
                screen={screens.outcome}
                outcome={mapElement?.outcomes?.[selectedOutcomeIndex]}
                onSave={() => onSwitchScreen(screens.step)}
                onCancel={() => {
                    onSwitchScreen(screens.step);
                    setSelectedOutcomeIndex(null);
                }}
            />
        </ScreenRouter>
    );
};

export default StepRouter;
