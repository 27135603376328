import ContainerTypeSelect from './ContainerTypeSelect';
import ButtonIcon from '../../../../../../buttons/ButtonIcon';
import classNames from 'classnames';
import translations from '../../../../../../../translations';

import { useComposer } from '../../ComposerProvider';
import { isNullOrEmpty } from '../../../../../../../utils/guard';
import { Warning, Trash } from '@phosphor-icons/react';
import type { LegacyRef, ReactElement } from 'react';
import type { ContainerType } from '../../../../dnd-utils';
import type { ComposerElement, PreviewMode } from '../../../../../../../types';

interface Props {
    id: string;
    type: ContainerType;
    parentId: string | null | undefined;
    classes: string;
    drag: LegacyRef<HTMLDivElement> | null;
    children: ReactElement[];
    config: ComposerElement;
    preview: PreviewMode | null;
}

const PageContainer = ({ id, type, parentId, classes, drag, children, config, preview }: Props) => {
    const { developerName, label } = config;
    const validated = !isNullOrEmpty(developerName);
    const { setElementToDelete } = useComposer();

    const isRoot = isNullOrEmpty(parentId);

    const headerClasses = classNames({
        'page-container-header': true,
        draggable: true,
        preview: !!preview,
    });

    const isDraggable = !(preview || isRoot);

    return (
        <div
            id={id}
            // biome-ignore lint/a11y/useSemanticElements: Requires refactor
            role="button"
            ref={isDraggable ? drag : null}
            tabIndex={-1}
            className="page-container-inner"
        >
            <div className={headerClasses}>
                <span title={config.developerName || ''} className="page-element-title">
                    {developerName}
                </span>
                <div className="container-header-actions flex-child-right">
                    <div className="container-type">
                        <ContainerTypeSelect containerId={id} selectedContainerType={type} />
                    </div>
                    {!isRoot && (
                        <ButtonIcon
                            className="header-icon-button"
                            title="Delete Container"
                            onClick={() => setElementToDelete({ targetId: id })}
                        >
                            <Trash size={20} weight="fill" />
                        </ButtonIcon>
                    )}
                </div>
            </div>
            <div className={classes}>
                {label ? <p>{label}</p> : null}
                {children}
            </div>
            {!validated && (
                <mark
                    title={translations.PAGE_BUILDER_unconfigured_container}
                    className="config-warning"
                    data-testid="mark"
                >
                    <Warning weight="fill" />
                </mark>
            )}
        </div>
    );
};

export default PageContainer;
