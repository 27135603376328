import translations from '../../translations';
import type { PackageConflictResponse, PackageElement, PackageElements } from '../../types/flow';
import CopyableText from './CopyableText';
import Table, { type TableColumnList } from './Table';

const mapDependentToName = (element: keyof PackageElements) => {
    switch (element) {
        case 'flows':
            return translations.IMPORT_EXPORT_conflicting_dependencies_table_flow_name;
        case 'groupElements':
            return translations.IMPORT_EXPORT_conflicting_dependencies_table_group_element_name;
        case 'macroElements':
            return translations.IMPORT_EXPORT_conflicting_dependencies_table_macro_element_name;
        case 'mapElements':
            return translations.IMPORT_EXPORT_conflicting_dependencies_table_map_element_name;
        case 'navigationElements':
            return translations.IMPORT_EXPORT_conflicting_dependencies_table_navigation_element_name;
        case 'pageElements':
            return translations.IMPORT_EXPORT_conflicting_dependencies_table_page_element_name;
        case 'serviceElements':
            return translations.IMPORT_EXPORT_conflicting_dependencies_table_service_element_name;
        case 'tagElements':
            return translations.IMPORT_EXPORT_conflicting_dependencies_table_tag_element_name;
        case 'typeElements':
            return translations.IMPORT_EXPORT_conflicting_dependencies_table_type_element_name;
        case 'valueElements':
            return translations.IMPORT_EXPORT_conflicting_dependencies_table_value_element_name;
        case 'customPageComponentElements':
            return translations.IMPORT_EXPORT_conflicting_dependencies_table_custom_page_component_element_name;
        case 'identityProviderElements':
            return translations.IMPORT_EXPORT_conflicting_dependencies_table_identity_provider_element_name;
        default:
            return translations.IMPORT_EXPORT_conflicting_dependencies_table_unknown_element_name;
    }
};

interface DependencyTableProps {
    dependents: PackageConflictResponse;
}

/**
 * Render a table of dependencies
 *
 * @param dependents Object with a list of elements for each dependency
 */
const DependencyTable = ({ dependents: { existingElements } }: DependencyTableProps) => {
    interface Dependency extends PackageElement {
        element: keyof PackageElements;
    }

    const columns: TableColumnList<Dependency> = [
        {
            renderHeader: () =>
                translations.IMPORT_EXPORT_conflicting_dependencies_table_name_column_heading,
            renderCell: ({ item: { developerName } }) => developerName,
        },
        {
            renderHeader: () =>
                translations.IMPORT_EXPORT_conflicting_dependencies_table_type_column_heading,
            renderCell: ({ item: { element } }) => mapDependentToName(element),
        },
        {
            renderHeader: () =>
                translations.IMPORT_EXPORT_conflicting_dependencies_table_id_column_heading,
            renderCell: ({ item: { id } }) => (
                <CopyableText copyableText={id} hasCopyButton={true} />
            ),
        },
    ];

    // Reshape our grouped data into a list of objects for <Table> to consume.
    const items = Object.keys(existingElements).flatMap((e) => {
        const element = e as keyof typeof existingElements;

        return existingElements[element].map((item) => ({
            element,
            ...item,
        }));
    });

    return (
        <Table
            items={items}
            columns={columns}
            caption={translations.IMPORT_EXPORT_conflicting_dependencies_table_caption}
            pagination={true}
        />
    );
};

export default DependencyTable;
