import PageConfiguration from './PageConfiguration';
import OutcomeDetails from '../../../../../ts/components/flow/elementConfigurations/common/OutcomeDetails';
import NavigationOverrideDetails from '../common/navigationOverrides/NavigationOverrideDetails';
import screens from '../common/screens';
import ScreenRouter from '../../../../../ts/components/generic/ScreenRouter';

import { useMapElement } from '../contextProviders/MapElementProvider';
import MessageActionDetails from '../common/messageActions/MessageActionDetails';
import ListenerDetails from '../common/listeners/ListenerDetails';
import WaitDetails from '../../../../../ts/components/flow/elementConfigurations/common/wait/WaitDetails';

const PageRouter = () => {
    const {
        currentScreen,
        mapElement,
        selectedOutcomeIndex,
        onReturnToDefaultScreen,
        setSelectedOutcomeIndex,
    } = useMapElement();

    return (
        <ScreenRouter currentScreen={currentScreen}>
            <PageConfiguration screen={screens.page} />
            <OutcomeDetails
                screen={screens.outcome}
                outcome={mapElement?.outcomes?.[selectedOutcomeIndex]}
                onSave={onReturnToDefaultScreen}
                onCancel={() => {
                    onReturnToDefaultScreen();
                    setSelectedOutcomeIndex(null);
                }}
            />
            <MessageActionDetails screen={screens.messageActions} />
            <ListenerDetails screen={screens.listener} />
            <NavigationOverrideDetails screen={screens.navigationOverrides} />
            <WaitDetails screen={screens.wait} />
        </ScreenRouter>
    );
};

export default PageRouter;
